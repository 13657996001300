/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import axios from "axios";
import { useSelector } from "react-redux"
import { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom"

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";

import DataTable from "../../examples/Tables/DataTable";

// Data
import authorsTableData from "./data/authorsTableData";
import { ecoles } from "../../utils/api/api";
import Loader from "../../components/Loader/Loader";
import Modale from "../../examples/Modal/Modal";

function Tables() {
  const [schools, setSchools] = useState([])
  const [loading, setLoading] = useState(true)
  const currentUser = useSelector(state => state.user.value)
  const { columns, rows2 } = authorsTableData(schools);
  const navibation = useNavigate()
  // console.log(rows);
  // console.log(rows2);
  // const { columns: pColumns, rows: pRows } = projectsTableData();
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    if (!currentUser.length) {
      navibation("/authentication/sign-in")
    }
    // eslint-disable-next-line
  },[currentUser.length])

  const setAllSchools = async () => {
    try {
      const { data }= await axios.get(ecoles)
      setSchools(data)
      setLoading(false)
      // console.log(data);
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }

  useEffect(() => {
    setAllSchools()
  }, [isOpen])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {
            !loading ?
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="secondary"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Liste des Ecoles
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows: rows2[0] }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
                <button onClick={()=>setIsOpen(true)} style={{
                  padding:"15px",
                  marginTop:"20px",
                  cursor:"pointer",
                  backgroundColor:"#000000",
                  color:"#ffffff",
                  borderRadius:"5px",
                  border:"none",
                  outline:"none",
                  fontSize:"15px",
                  fontWeight:"bold",
                  letterSpacing:"1.5px",
                  textTransform:"uppercase",
                  textAlign:"center",
                }}>
                  Ajouter
                </button>
              </Grid> :
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100vw",
              }}>
                <Loader />
              </div>
              
          }

          {/* <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid> */}
        </Grid>
        {
          isOpen && <Modale isOpen={isOpen} setIsOpen={setIsOpen} currentUser={currentUser} setLoading={setLoading}/>
        }
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
