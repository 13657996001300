/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import ReportsBarChart from "../../examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "../../examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "./data/reportsBarChartData";
import reportsLineChartData from "./data/reportsLineChartData";

// Dashboard components
import Projects from "./components/Projects";
import OrdersOverview from "./components/OrdersOverview";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify"

import { chauffeurs, childrenOneSchool, flots, getAllBus, getAllChildren, getAllDriver, getChildTransportToday, getOneSchool } from "../../utils/api/api";
import Loader from "../../components/Loader/Loader";


function Dashboard() {
  const currentUser = useSelector(state => state.user.value[0])
  const [loader, setLoader] = useState(true)
  const [driver, setDriver] = useState(0)
  const [driverStatus, setDriverStatus] = useState([])
  const [flottes, setFlottes] = useState([])
  const [children, setChildren] = useState([])
  const [totalChild, setTotalChild] = useState([])
  const [childTransportToday, setChildTransportToday] = useState(0)
  // console.log(currentUser)
  const { sales, tasks } = reportsLineChartData;

  const updateData = async () => {
    setLoader(true)
    try {
      if (currentUser.role === 3) {
        const myFlotte = await axios.get(flots)
        const myDriver = await axios.get(getAllDriver)
        const allChildren = await axios.get(getAllChildren, {
          headers: {
            Authorization: `Bearer ${currentUser.token}`
          }
        })
        setChildren(allChildren.data)
        setFlottes(myFlotte.data)
        let driverRider = []
        myDriver.data.forEach(element => {
          if (element.status === true) {
            driverRider.push(element)
          }
        });
        setDriver(myDriver.data.length)
        setDriverStatus(driverRider)
        setLoader(false)
        return
      }
      if (currentUser.role === 2) {
        const { data } = await axios.get(`${childrenOneSchool}/${currentUser._id}`, {
          headers: {
            Authorization: `Bearer ${currentUser.token}`
          }
        })
        let childTransport = []
        data.forEach(element => {
          if (element.bus || element.chauffeur) {
            childTransport.push(element)
          }
        })
        setChildren(childTransport)
        setTotalChild(data)
        const response = await axios.get(`${getAllBus}/${currentUser._id}`)
        let busEnCirculation = []
        response.data.forEach(element => {
          if (element.status === true) {
            busEnCirculation.push(element)
          }
        });
        setDriver(busEnCirculation)
        setDriverStatus(response.data)
        const date = new Date()
        const newDate = {
          day: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
        }
        const date1 = new Date(`
          ${newDate.year}-
          ${newDate.month}-
          ${newDate.day}
        `)
        const responseData = await axios.post(`${getChildTransportToday}/${currentUser._id}`, {
          date: date1.toString()
        },
          {
            headers: {
              'Authorization': `Bearer ${currentUser.token}`
            }
          })
        console.log(responseData.data)
        let nombreEnfants = 0
        responseData.data.map(item => {
          nombreEnfants += item.enfants.length
        })
        setChildTransportToday(nombreEnfants)
        console.log(nombreEnfants)
        setLoader(false)
        return
      }
      if (currentUser.role === 1) {
        const { data } = await axios.get(`${chauffeurs}/${currentUser._id}`, {
          headers: {
            Authorization: `Bearer ${currentUser.token}`
          }
        })
        let driverRider = []
        let children = 0
        data.forEach(element => {
          children = children + element.enfants.length
          if (element.status === true) {
            driverRider.push(element)
          }
        });
        setDriver(data.length)
        setDriverStatus(driverRider)
        setChildren(children)
        setLoader(false)
        return
      }

    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message)
      setLoader(false)
    }
  }

  useEffect(() => {
    updateData()
    // eslint-disable-next-line
  }, [])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {
        loader ? <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Loader />
        </div> :
          <MDBox py={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  {currentUser.role === 3 && <ComplexStatisticsCard
                    color="dark"
                    icon="weekend"
                    title="Bookings"
                    count={281}
                    percentage={{
                      color: "success",
                      amount: "+55%",
                      label: "nombre de partenaire de flotte",
                    }}
                  />}
                  {currentUser.role === 2 && <ComplexStatisticsCard
                    color="dark"
                    // icon="weekend"
                    title="éleves transportés"
                    count={children.length || 0}
                    percentage={{
                      color: "success",
                      amount: totalChild.length,
                      label: " éleves au total",
                    }}
                  />}
                  {currentUser.role === 1 && <ComplexStatisticsCard
                    color="dark"
                    icon="weekend"
                    title="Voiture en circulation"
                    count={driverStatus.length}
                    percentage={{
                      color: "success",
                      amount: `${driver}`,
                      label: "voitures au total",
                    }}
                  />}
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  {currentUser.role === 3 && <ComplexStatisticsCard
                    icon="leaderboard"
                    title="Today's Users"
                    count="2,300"
                    percentage={{
                      color: "success",
                      amount: "+3%",
                      label: "nombre d'école ",
                    }}
                  />}
                  {currentUser.role === 2 && <ComplexStatisticsCard
                    // icon="leaderboard"
                    title="Bus en circulation"
                    count={driver.length || 0}
                    percentage={{
                      color: "success",
                      amount: driverStatus.length,
                      label: " de bus au total ",
                    }}
                  />}
                  {currentUser.role === 1 && <ComplexStatisticsCard
                    title="Nombre d'enfant à transporter"
                    count={children}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+3%",
                  //   label: "nombre d'éleve transporté ",
                  // }}
                  />}
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  {currentUser.role === 2 &&
                    <ComplexStatisticsCard
                      color="success"
                      icon="store"
                      title="Enfants transportés aujourd'hui"
                      count={childTransportToday}
                      percentage={{
                        color: "success",
                        amount: children.length,
                        label: " enfants sont attendus",
                      }}
                    />
                  }
                  {currentUser.role === 3 &&
                    <ComplexStatisticsCard
                      color="success"
                      icon="store"
                      title="Nombre d'enfant"
                      count={children.length}
                      percentage={{
                        color: "success",
                        amount: "+0",
                        label: "nombre d'enfant transpotré",
                      }}
                    />
                  }
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  {currentUser.role === 3 &&
                    <ComplexStatisticsCard
                      color="primary"
                      icon="person_add"
                      title="Followers"
                      count="+91"
                      percentage={{
                        color: "success",
                        amount: "",
                        label: "Nombre d'enfant transporté",
                      }}
                    />
                  }
                </MDBox>
              </Grid>
            </Grid>
            <MDBox mt={4.5}>
             {currentUser.role === 2 && <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={3}>
                    <ReportsBarChart
                      color="info"
                      title="Enfants transportés cette semaine"
                      description="Last Campaign Performance"
                      date="campaign sent 2 days ago"
                      chart={reportsBarChartData}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={3}>
                    <ReportsLineChart
                      color="success"
                      title="daily sales"
                      description={
                        <>
                          (<strong>+15%</strong>) increase in today sales.
                        </>
                      }
                      date="updated 4 min ago"
                      chart={sales}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={3}>
                    <ReportsLineChart
                      color="dark"
                      title="completed tasks"
                      description="Last Campaign Performance"
                      date="just updated"
                      chart={tasks}
                    />
                  </MDBox>
                </Grid>
              </Grid>}
            </MDBox>
            {/* <MDBox>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={8}>
                  <Projects />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <OrdersOverview />
                </Grid>
              </Grid>
            </MDBox> */}
          </MDBox>
      }

      <ToastContainer />
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
