import React, { useEffect, useState } from 'react'
import Footer from '../../examples/Footer'
import Loader from '../../components/Loader/Loader'
import DataTable from '../../examples/Tables/DataTable'
import MDTypography from '../../components/MDTypography'
import MDBox from '../../components/MDBox'
import { Card, Grid } from '@mui/material'

import authorsTableData from "./data/authorsTableData";
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar'
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout'
import { getAllAbonnementsSchool } from '../../utils/api/api'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import Modale from './Modale'

function Abonnement() {
    const currentUser = useSelector(state=>state.user.value[0])
    const [abonnement, setAbonnement] = useState([])
    const { columns, rows2 } = authorsTableData(abonnement);
    const [loading, setLoading] = useState(true)
    const [isOpen, setIsOpen] = useState(false)

    const updateAbonnement = async()=>{
        try {
            const {data} = await axios.get(`${getAllAbonnementsSchool}/${currentUser._id}`)
            setAbonnement(data)
            setLoading(false)
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }
    useEffect(()=>{
        updateAbonnement()
    },[isOpen])
  return (
    <DashboardLayout>
    <DashboardNavbar />
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        {
          !loading ?
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="secondary"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Liste des Flotes
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows: rows2[0] }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
              <button onClick={()=>setIsOpen(true)} style={{
                padding:"15px",
                marginTop:"20px",
                cursor:"pointer",
                backgroundColor:"#000000",
                color:"#ffffff",
                borderRadius:"5px",
                border:"none",
                outline:"none",
                fontSize:"15px",
                fontWeight:"bold",
                letterSpacing:"1.5px",
                textTransform:"uppercase",
                textAlign:"center",
              }}>
                Ajouter
              </button>
            </Grid> :
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "100vw",
            }}>
              <Loader />
            </div>
            
        }

        {/* <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Projects Table
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={{ columns: pColumns, rows: pRows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        </Grid> */}
      </Grid>
      {
        isOpen && <Modale isOpen={isOpen} setIsOpen={setIsOpen} currentUser={currentUser} setLoading={setLoading}/>
      }
    </MDBox>
    <ToastContainer/>
    <Footer />
  </DashboardLayout>
  )
}

export default Abonnement
