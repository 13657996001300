import axios from 'axios';
import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import { ToastContainer, toast } from 'react-toastify';
import imageCompression from 'browser-image-compression';
import { createBus } from '../../../../utils/api/api';


function Modale({ isOpen, setIsOpen, currentUser, setLoading }) {
    const [isLoader, setIsLoader] = useState(false)
    const [bus, setbus] = useState([])
    const onCloseModal = () => setIsOpen(false);
    const handleChange = (e) => {
        setbus({ ...bus, [e.target.name]: e.target.value })
    }
    const compressImage = async (e)=>{
        const file  = e.target.files[0]
        const option={
            maxSizeMB: 0.5,
            maxWidthOrHeight:1000
        }
        try {
            const compressFile = await imageCompression(file,option)
            var reader = new FileReader()
            reader.readAsDataURL(compressFile)
            reader.onload = ()=>{
                setbus({
                    ...bus, [e.target.name]: reader.result
                })
                console.log(reader.result)
            }
        } catch (error) {
            toast.error("le fichier doit etre une image")
            console.log(error);
        }
    }
    const handleSubmit = async () => {
        setIsLoader(true)
        if (!bus.nom || !bus.cni  || !bus.email || !bus.password
            || !bus.phone || !bus.photo || !bus.localisation 
            ) {
            toast.error("Veuillez remplir tous les champs")
            setIsLoader(false)
            return
        }
        console.log(currentUser);
        try {
            await axios.post(`${createBus}/${currentUser[0]._id}`,bus,{
                headers: {
                    'Authorization': `Bearer ${currentUser[0].token}`,
                }
            })
            setIsLoader(false)
            setIsOpen(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setIsLoader(false)
        }
    }
    return (
        <div>
            <Modal open={isOpen} onClose={onCloseModal} center classNames={{
                modal: 'modalContainer'
            }}>
                <div className="containerInscription">
                    <h2>Nouveau Bus</h2>
                    <div className='containerInput'>
                        <div className='inputItem' style={{ marginTop: "30px" }}>
                            <label htmlFor="nom">Nom <span>*</span> </label>
                            <input required placeholder="Enter your text..." className="input" name="nom" onChange={(e) => handleChange(e)} type="text"  />
                        </div>
                        <div className='inputItem' style={{ marginTop: "30px" }}>
                            <label htmlFor="nom">Email <span>*</span> </label>
                            <input required placeholder="Enter your text..." className="input" name="email" onChange={(e) => handleChange(e)} type="email"  />
                        </div>

                    </div>
                    <div className='containerInput'>
                        <div className='inputItem' style={{ marginTop: "30px" }}>
                            <label htmlFor="nom">Plan Localisatoin <span>*</span> </label>
                            <input required placeholder="Enter your text..." accept='image/*' className="input" name="localisation" onChange={(e) => compressImage(e)} type="file" />
                        </div>
                        <div className='inputItem' style={{ marginTop: "30px" }}>
                            <label htmlFor="nom">Photo <span>*</span> </label>
                            <input required placeholder="Enter your text..." accept='image/*' className="input" name="photo" onChange={(e) => compressImage(e)} type="file" />
                        </div>

                    </div>
                    <div className='containerInput'>
                        <div className='inputItem'>
                            <label htmlFor="classw">Matricule <span>*</span></label>
                            <input required placeholder="Enter your text..." className="input" name="matricule" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                        <div className='inputItem'>
                            <label htmlFor="classw">Mot de passe <span>*</span></label>
                            <input required placeholder="Enter your text..." className="input" name="password" onChange={(e) => handleChange(e)} type="password" />
                        </div>
                    </div>
                    <div className='containerInput'>
                        <div className='inputItem'>
                            <label htmlFor="classw">Places <span>*</span></label>
                            <input required placeholder="Enter your text..." className="input" name="places" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                        <div className='inputItem'>
                            <label htmlFor="classw">Cni <span>*</span></label>
                            <input required placeholder="Enter your text..." className="input" name="cni" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                    </div>
                    <div className='containerInput'>
                        <div className='inputItem'>
                            <label htmlFor="classw">Phone <span>*</span></label>
                            <input required placeholder="Enter your text..." className="input" name="phone" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                      
                    </div>
                    
                    <button className="animated-button" onClick={handleSubmit}>
                        <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                            ></path>
                        </svg>
                        {
                            isLoader ?
                                <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                    ></path>
                                </svg>
                                : <span className="text" >Ajouter</span>
                        }

                        <span className="circle"></span>
                        <svg viewBox="0 0 24 24" className="arr-1" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                            ></path>
                        </svg>
                    </button>

                </div>

            </Modal>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false} />
        </div>
    )
}

export default Modale
