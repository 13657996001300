/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { updatePasswordFlot } from "../../../../utils/api/api";
import Loader from "../../../../components/Loader/Loader";

function PlatformSettings({ currentUser }) {
  const [password, setPassword] = useState({})
  const [isLoader, setIsLoader] = useState(false)
  const handleChange = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value })
  }
  const handleSubmit = async () => {
    setIsLoader(true)
    console.log(password);
    if (!password.password || !password.newPassword || !password.confirmPassword) {
      toast.error("Veuillez remplir tous les champs")
      setIsLoader(false)
      return
    }
    if (password.newPassword !== password.confirmPassword) {
      toast.error("Les nouveaux mots de passe ne correspondent pas")
      setIsLoader(false)
      return
    }
    try {
      if (currentUser.role === 1) {
        const { data } = await axios.patch(`${updatePasswordFlot}/${currentUser._id}`, {
          password: password.password,
          newPassword: password.newPassword,
          email: currentUser.email
        })
        console.log(data);
      }

      toast.success("mot de passe modifié")
      setIsLoader(false)
      setPassword({})
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message)
      setIsLoader(false)
      setPassword({})
    }
    console.log(password);
  }

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Parametre
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          Modifier le mot de passe
        </MDTypography>
        <MDBox alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox width="100%" mt={3} style={{ fontWeight: "bold", fontSize: "20px" }} ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Entrer l'acien mot de passe
            </MDTypography>
          </MDBox>
          <MDBox mt={0.5}>
            <input style={{
              backgroundColor: "#e1ecf2",
              width: "90%",
              /* max-width: 190px; */
              color: "black",
              height: "40px",
              padding: "10px",
              /* text-align: center; */
              border: "2px solid white",
              borderRadius: "5px",
              /* box-shadow: 3px 3px 2px rgb(249, 255, 85); */
            }}
              type="password"
              name="password"
              onChange={(e) => handleChange(e)}
              placeholder="entrer le mot de passe"
              data-lpignore={true}
              />
            {/* <Switch checked={followsMe} onChange={() => setFollowsMe(!followsMe)} /> */}
          </MDBox>

        </MDBox>
        <MDBox alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox width="100%" mt={5} style={{ fontWeight: "bold", fontSize: "20px" }} ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Entrer le nouveau mot de passe
            </MDTypography>
          </MDBox>
          <MDBox mt={0.5}>
            <input style={{
              backgroundColor: "#e1ecf2",
              width: "90%",
              /* max-width: 190px; */
              color: "black",
              height: "40px",
              padding: "10px",
              /* text-align: center; */
              border: "2px solid white",
              borderRadius: "5px",
              /* box-shadow: 3px 3px 2px rgb(249, 255, 85); */
            }}
              type="password"
              name="newPassword"
              onChange={(e) => handleChange(e)}
              data-lpignore={true}
              placeholder="entrer le mot de passe" />
            {/* <Switch checked={followsMe} onChange={() => setFollowsMe(!followsMe)} /> */}
          </MDBox>

        </MDBox>
        <MDBox alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox width="400px" mt={2} style={{ fontWeight: "bold", fontSize: "20px" }} ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Entrer le nouveau mot de passe                                :
            </MDTypography>
          </MDBox>
          <MDBox mt={0.5} >
            <input style={{
              backgroundColor: "#e1ecf2",
              width: "90%",
              /* max-width: 190px; */
              color: "black",
              height: "40px",
              padding: "10px",
              /* text-align: center; */
              border: "2px solid white",
              borderRadius: "5px",
              /* box-shadow: 3px 3px 2px rgb(249, 255, 85); */
            }}
              type="password"
              name="confirmPassword"
              onChange={(e) => handleChange(e)}
              data-lpignore={true}
              placeholder="entrer le mot de passe" />

          </MDBox>

        </MDBox>
        <div style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "90%",
        }}>
          <button
            style={{
              backgroundColor: "#e1ecf2",
              width: "100px",
              color: "black",
              height: "40px",
              padding: "10px",
              border: "2px solid white",
              borderRadius: "5px",
              cursor: "pointer"
            }}
            onClick={handleSubmit}
          >
            {isLoader ? <Loader /> : ("Modifier")}
          </button>
        </div>
      </MDBox>
      <ToastContainer />
    </Card>
  );
}

export default PlatformSettings;
