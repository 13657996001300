import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar'
import MDBox from '../../../components/MDBox'
import { Card, Grid } from '@mui/material'
import MDTypography from '../../../components/MDTypography'
import DataTable from '../../../examples/Tables/DataTable'
import Footer from '../../../examples/Footer'
import Loader from '../../../components/Loader/Loader'
import authorsTableData from "./data/authorsTableData"
import { useSelector } from 'react-redux'
import { childrenOneSchool, deleteChild, getAllAbonnementsSchool, getOneSchool, updateUserAbonnement } from '../../../utils/api/api'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Modale from './Modal/Modal'
import { toast, ToastContainer } from 'react-toastify'
import MDInput from '../../../components/MDInput'

import "./eleve.css"

function Eleves() {
  const [loading, setLoading] = useState(true)
  console.log(loading)
  const [openMenu, setOpenMenu] = useState(false)
  console.log(loading)
  const navigate = useNavigate()
  const [children, setChildren] = useState([])
  const [abonnements, setAbonnements] = useState([])
  const [allChildren, setAllChildren] = useState([])
  const currentUser = useSelector(state => state.user.value)
  const [isOpen, setIsOpen] = useState(false)
  const [updated, setUpdated] = useState(false)
  const updateStatus = async (id) => {
    setUpdated(true)
    try {
      await axios.get(`${updateUserAbonnement}/${id}`, {
        headers: {
          'Authorization': `Bearer ${currentUser[0].token}`
        }
      })
      const { data } = await axios.get(`${childrenOneSchool}/${currentUser[0]._id}`, {
        headers: {
          'Authorization': `Bearer ${currentUser[0].token}`
        }
      })
      setChildren(data)
      setUpdated(false)
      toast.success("Le compte à été activé")
    } catch (error) {
      setLoading(false)
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
        return
      }
      toast.error("Erreur lors de l'activation du compte")
    }
  }
  const deletedChild = async (id) => {
    setUpdated(true)
    try {
      await axios.delete(`${deleteChild}/${id}`, {
        headers: {
          "Authorization": `Bearer ${currentUser[0].token}`
        }
      })
      const { data } = await axios.get(`${childrenOneSchool}/${currentUser[0]._id}`, {
        headers: {
          'Authorization': `Bearer ${currentUser[0].token}`
        }
      })
      setChildren(data)
      setUpdated(false)
      toast.success("Le compte à été supprimé")

    } catch (error) {
      setUpdated(false)
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
        return
      }
      toast.error("Erreur lors de la suppression du compte")
    }
  }
  const { columns, rows2 } = authorsTableData(children, updateStatus, updated, openMenu, setOpenMenu, deletedChild);

  useEffect(() => {
    if (!currentUser.length) {
      navigate("/authentication/sign-in")
    }
    // eslint-disable-next-line
  }, [currentUser.length])
  const setAllSchools = async () => {
    try {
      const { data } = await axios.get(`${childrenOneSchool}/${currentUser[0]._id}`, {
        headers: {
          'Authorization': `Bearer ${currentUser[0].token}`
        }
      })
      const response = await axios.get(`${getAllAbonnementsSchool}/${currentUser[0]._id}`)
      setAbonnements(response.data)
      setChildren(data)
      setAllChildren(data)
      setLoading(false)
      console.log(data);
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }

  const searchInput =(e)=>{
    const searchChild = allChildren
    setChildren(searchChild.filter(child => child.nom?.toLowerCase().includes(e.target.value.toLowerCase()) || child.email?.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  useEffect(() => {
    setAllSchools()
    // eslint-disable-next-line
  }, [isOpen, loading])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {
            !loading ?
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="secondary"
                    borderRadius="lg"
                    coloredShadow="info"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <MDTypography variant="h6" color="white">
                      Liste des Eleves
                    </MDTypography>
                    <div class="group">
                      <svg class="icon" aria-hidden="true" viewBox="0 0 24 24">
                        <g>
                          <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z">
                          </path>
                        </g>
                      </svg>
                      <input onChange={(e)=>searchInput(e)} placeholder="Search" type="search" class="input" />
                    </div>

                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows: rows2[0] }}
                      isSorted={false}
                      entriesPerPage={true}
                      showTotalEntries={true}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
                <button onClick={() => setIsOpen(true)} style={{
                  padding: "15px",
                  marginTop: "20px",
                  cursor: "pointer",
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  borderRadius: "5px",
                  border: "none",
                  outline: "none",
                  fontSize: "15px",
                  fontWeight: "bold",
                  letterSpacing: "1.5px",
                  textTransform: "uppercase",
                  textAlign: "center",
                }}>
                  Ajouter un nouvelle enfant
                </button>
              </Grid> :
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100vw",
              }}>

                <Loader />
              </div>

          }


        </Grid>
        {
          isOpen && <Modale isOpen={isOpen} setIsOpen={setIsOpen} abonnements={abonnements} currentUser={currentUser[0]} setLoading={setLoading} />
        }
      </MDBox>
      <ToastContainer />
      <Footer />
    </DashboardLayout>
  )
}

export default Eleves
