/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "./layouts/dashboard";
import Tables from "./layouts/tables";
import Billing from "./layouts/billing";
import Notifications from "./layouts/notifications";
import Profile from "./layouts/profile";
import SignIn from "./layouts/authentication/sign-in";
import SignUp from "./layouts/authentication/sign-up";
import SigninAuth from "./layouts/authentication/sign-in-admin";
import Flot from "./layouts/Flots"
import Eleves from "./layouts/ecoles/Eleves";
import Bus from "./layouts/ecoles/Bus"

// @mui icons
import Icon from "@mui/material/Icon";
import {  FaBell, FaCar, FaChild, FaEvernote, FaHome, FaMapSigns, FaMoneyCheck, FaSchool, FaSolarPanel, FaUserEdit } from "react-icons/fa";
import Voitures from "./layouts/voiture";
import Wrapper from "./layouts/R2S/Wrapper";
import MyMap from "./layouts/R2S/Wrapper";
import Urgence from "./layouts/Urgence";
import ProfileUser from "./layouts/ProfileUser";
import Abonnement from "./layouts/abonnement";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <FaHome/>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Ecoles",
    key: "tables",
    icon: <FaSchool/>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Flottes",
    key: "billing",
    icon: <FaCar size={30}/>,
    route: "/flote",
    component: <Flot />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <FaEvernote/>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profil",
    key: "profile",
    icon: <FaUserEdit/>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "ProfilUser",
    key: "profileUser",
    icon: <FaUserEdit/>,
    route: "/admin/car/profile/:id",
    component: <ProfileUser />,
  },
  {
    type: "collapse",
    name: "detail",
    key: "detail",
    icon: <FaUserEdit/>,
    route: "/detail/:idFlote",
    component: <Voitures />,
  },

  {
    type:"collapse",
    name:"R2S",
    key:"r2s",
    icon:<FaMapSigns/>,
    route:"/r2s/:id",
    component:<Wrapper/>
  },
  {
    type:"collapse",
    name:"Urgences",
    key:"urgneces",
    icon:<FaSolarPanel/>,
    route:"/urgences/:id",
    component:<Urgence/>
  },
  
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Abonnemnt",
    key: "abonnement",
    icon: <FaMoneyCheck/>,
    route: "/abonnement",
    component: <Abonnement />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/admin/authentication/sign-in",
    component: <SigninAuth />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
];

export const routeEcole= [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <FaHome/>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "ProfilUser",
    key: "profileUser",
    icon: <FaUserEdit/>,
    route: "/ecole/car/profile/:id",
    component: <ProfileUser />,
  },
  {
    type:"collapse",
    name:"R2S",
    key:"r2s",
    icon:<FaMapSigns/>,
    route:"/r2s/:id",
    component:<Wrapper/>
  },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <Billing />,
  // },
  {
    type: "collapse",
    name: "Eleves",
    key: "eleves",
    icon: <FaChild/>,
    route: "/eleves",
    component: <Eleves />,
  },
  {
    type: "collapse",
    name: "Bus",
    key: "bus",
    icon: <FaCar/>,
    route: "/bus",
    component: <Bus />,
  },
  // {
  //   type:"collapse",
  //   name:"Urgences",
  //   key:"urgences",
  //   icon:<FaSolarPanel/>,
  //   route:"/urgences/:id",
  //   component:<Wrapper/>
  // },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <FaUserEdit/>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Abonnemnt",
    key: "abonnement",
    icon: <FaMoneyCheck/>,
    route: "/abonnement",
    component: <Abonnement />,
  },
]

export const routeFlote= [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <FaHome/>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "ProfilUser",
    key: "profileUser",
    icon: <FaUserEdit/>,
    route: "/flotte/car/profile/:id",
    component: <ProfileUser />,
  },
  {
    type: "collapse",
    name: "Voitures",
    key: "voitures",
    icon: <FaCar/>,
    route: "/voiture",
    component: <Voitures />,
  },
  
  {
    type:"collapse",
    name:"r2s",
    key:"r2s",
    icon:<FaMapSigns/>,
    route:"/r2s/:id",
    component:<MyMap/>
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <FaBell/>,
    route: "/notifications",
    component: <Voitures />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <FaUserEdit/>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type:"collapse",
    name:"Urgneces",
    key:"urgneces",
    icon:<FaSolarPanel/>,
    route:"/urgences/:id",
    component:<Wrapper/>
  },
]

export default routes;
