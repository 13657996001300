import React, { useEffect, useState } from 'react'
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Footer from '../../examples/Footer'
import { toast, ToastContainer } from 'react-toastify'
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar'
import MDBox from '../../components/MDBox'
import Loader from '../../components/Loader/Loader'
import Header from '../profile/components/Header'
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import ProfileInfoCard from '../../examples/Cards/InfoCards/ProfileInfoCard'
import PlatformSettings from '../profile/components/PlatformSettings'
import ProfilesList from '../../examples/Lists/ProfilesList'
import profilesListData from '../profile/data/profilesListData'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios';
import { deletDriverChild, deleteChildBus, getAllChildren, getOneBus, getOneDriver, updateStatus } from '../../utils/api/api';
import Modale from './Modale';
import { Switch } from '@mui/material';

function ProfileUser() {
  const { id } = useParams()
  const currentUser = useSelector(state => state.user.value)
  const [isOpen, setIsOpen] = useState(false)
  const [user, setUser] = useState([])
  const [loading, setLoading] = useState(true)
  const [child,setChild] = useState([])

  const url = window.location.href

  console.log(id)

  const setStatu = async () => {
    setLoading(true)
    try {
      await axios.get(`${updateStatus}/${id}`, {
        headers: {
          'Authorization': `Bearer ${currentUser[0].token}`
        }
      })
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }

  }

  const updateUser = async () => {
    console.log("ici")
    try {
      if (url.includes("ecole/car")) {
        const { data } = await axios.get(`${getOneBus}/${id}`)
        setUser(data)
        setLoading(false)
        console.log(data)
        return
      }
      if (url.includes("flotte/car")) {
        const { data } = await axios.get(`${getOneDriver}/${id}`)
        console.log(data)
        const response = await axios.get(getAllChildren, {
          headers: {
            'Authorization': `Bearer ${currentUser[0].token}`
          }
        })
        let dataChild = []
        // eslint-disable-next-line no-undef
        const directionService = new window.google.maps.DirectionsService()
        response.data.forEach(async (element) => {
          if ((!element.bus && !element.chauffeur) || (element.bus === null && element.chauffeur === null)) {
            const results = await directionService.route({
              origin: data.quartier,
              destination: { lat: parseFloat(element.ramassage[0].latitude), lng: parseFloat(element.ramassage[0].lontidute) },
              travelMode: window.google.maps.TravelMode.DRIVING
            })
            element.distance = results.routes[0].legs[0].distance.value/1000
            console.log(results.routes[0].legs[0].distance)
            dataChild.push(element)
            setChild(dataChild.sort(function compare(a,b){
              if(a.distance<b.distance) return -1
              if(a.distance>b.distance) return 1
              return 0
            }))
          }
          setUser(data)
          setLoading(false)
          console.log(data)
          return
        });
       
      }
    } catch (err) {
      console.log(err)
    }
  }

  const deletChild = async (idEnfant) => {
    try {
      if (url.includes("ecole/car")) {
        const { data } = await axios.post(`${deleteChildBus}/${id}`, {
          idEnfant: idEnfant
        })
        console.log(data)
        setIsOpen(true)
        return
      }
      console.log(idEnfant)
      const { data } = await axios.post(`${deletDriverChild}/${id}`, {
        idEnfant: idEnfant
      },
        {
          headers: {
            'Authorization': `Bearer ${currentUser[0].token}`
          }
        }
      )
      console.log(data)
      setIsOpen(true)

    } catch (error) {
      toast.error(error.response.data.message)
      console.log(error)
    }
  }

  useEffect(() => {
    updateUser()
  }, [isOpen, loading])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      {
        loading ?
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
          }}>
            <Loader />
          </div>
          :
          <Header currentUser={user}>
            <MDBox mt={5} mb={3}>
              <Grid container spacing={1}>

                <Grid item xs={12} md={6} xl={4}>
                  <ProfileInfoCard
                    title="Informations personnelles"
                    info={{
                      Nom: user.nom ? user.nom : user.nomEcole,
                      mobile: user?.phone,
                      email: user?.email,
                      ville: user.ville ? user.ville : user.adresse,
                      quartier:user.quartier,
                      description: url.includes("ecole/car") ? "chauffeur de bus" : "Chauffeur de taxi",
                      status: <Switch checked={user.status} onChange={() => setStatu()} />
                    }}
                    social={[
                      {
                        link: "#",
                        icon: <FacebookIcon />,
                        color: "facebook",
                      },
                      {
                        link: "#",
                        icon: <TwitterIcon />,
                        color: "twitter",
                      },
                      {
                        link: "#",
                        icon: <InstagramIcon />,
                        color: "instagram",
                      },
                    ]}
                    action={{ route: "", tooltip: "Edit Profile" }}
                    shadow={false}
                  />
                </Grid>
                {/* <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
                                    <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />

                                    <PlatformSettings currentUser={user} />
                                   
                                </Grid>  */}
                <Divider orientation="vertical" sx={{ mx: 0 }} />
                <Grid item xs={12} xl={4}>
                  <ProfilesList title="Quelques Enfants" deletChild={deletChild} chauffeurs={user.enfants} setStatu={setStatu} profiles={profilesListData} shadow={false} />
                  <button
                    style={{
                      width: "100%",
                      backgroundColor: "green",
                      color: "white",
                      marginBottom: "50px"
                    }}
                    onClick={() => setIsOpen(true)}
                  >ajouter un élève</button>
                </Grid>
              </Grid>
            </MDBox>
            {/* <MDBox pt={2} px={2} lineHeight={1.25}>
            <MDTypography variant="h6" fontWeight="medium">
              Projects
            </MDTypography>
            <MDBox mb={1}>
              <MDTypography variant="button" color="text">
                Architects design houses
              </MDTypography>
            </MDBox>
          </MDBox> */}
            {/* <MDBox p={2}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={homeDecor1}
                  label="project #2"
                  title="modern"
                  description="As Uber works through a huge amount of internal management turmoil."
                  action={{
                    type: "internal",
                    route: "/pages/profile/profile-overview",
                    color: "info",
                    label: "view project",
                  }}
                  authors={[
                    { image: team1, name: "Elena Morison" },
                    { image: team2, name: "Ryan Milly" },
                    { image: team3, name: "Nick Daniel" },
                    { image: team4, name: "Peterson" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={homeDecor2}
                  label="project #1"
                  title="scandinavian"
                  description="Music is something that everyone has their own specific opinion about."
                  action={{
                    type: "internal",
                    route: "/pages/profile/profile-overview",
                    color: "info",
                    label: "view project",
                  }}
                  authors={[
                    { image: team3, name: "Nick Daniel" },
                    { image: team4, name: "Peterson" },
                    { image: team1, name: "Elena Morison" },
                    { image: team2, name: "Ryan Milly" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={homeDecor3}
                  label="project #3"
                  title="minimalist"
                  description="Different people have different taste, and various types of music."
                  action={{
                    type: "internal",
                    route: "/pages/profile/profile-overview",
                    color: "info",
                    label: "view project",
                  }}
                  authors={[
                    { image: team4, name: "Peterson" },
                    { image: team3, name: "Nick Daniel" },
                    { image: team2, name: "Ryan Milly" },
                    { image: team1, name: "Elena Morison" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={homeDecor4}
                  label="project #4"
                  title="gothic"
                  description="Why would anyone pick blue over pink? Pink is obviously a better color."
                  action={{
                    type: "internal",
                    route: "/pages/profile/profile-overview",
                    color: "info",
                    label: "view project",
                  }}
                  authors={[
                    { image: team4, name: "Peterson" },
                    { image: team3, name: "Nick Daniel" },
                    { image: team2, name: "Ryan Milly" },
                    { image: team1, name: "Elena Morison" },
                  ]}
                />
              </Grid>
            </Grid>
          </MDBox> */}
          </Header>
      }
      <ToastContainer />
      <Modale isOpen={isOpen} children={child} user={user} id={id} setIsOpen={setIsOpen} />
      <Footer />
    </DashboardLayout>
  )
}

export default ProfileUser
