import React from 'react'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal'
import { useState } from 'react';
import imageCompression from 'browser-image-compression';
import { ToastContainer, toast } from 'react-toastify';
// import { Document, Page, Text, View, Table } from '@react-pdf/renderer';


import "./modal.css"
import axios from 'axios';
import { createFlot } from '../../utils/api/api';




function Modale({ isOpen, setIsOpen, currentUser, setLoading }) {
    const [school, setSchool] = useState({})
    const [isLoader, setIsLoader] = useState(false)
    const onCloseModal = () => setIsOpen(false);

    const handleChange = (e) => {
        setSchool({
            ...school, [e.target.name]: e.target.value
        })
    }
    const compressImage = async (e)=>{
        const file  = e.target.files[0]
        const option={
            maxSizeMB: 0.5,
            maxWidthOrHeight:1000
        }
        try {
            const compressFile = await imageCompression(file,option)
            var reader = new FileReader()
            reader.readAsDataURL(compressFile)
            reader.onload = ()=>{
                setSchool({
                    ...school, [e.target.name]: reader.result
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleSubmit = async () => {
        console.log(school)
        if(!school.nom || !school.email || !school.phone || !school.cni || !school.nombreVoiture || !school.localisation
             || !school.photoCni2 || !school.photoCni1 || !school.password || !school.logo || !school.nomFlotte || !school.niu ){
            toast.error("veillez remplir tous les champs")
            return
        }
        setIsLoader(true)
        try {
            const { data } = await axios.post(`${createFlot}/${currentUser[0].id}`,
                school,
                {
                    headers: {
                        'Authorization': `Bearer ${currentUser[0].token}`
                    }
                }
            )
            setIsLoader(false)
            setIsOpen(false)
            setLoading(true)
        } catch (error) {
            setIsLoader(false)
            toast.error(error.response.data.message)
            console.log(error);
        }

    }

    return (
        <div>
            <Modal open={isOpen} onClose={onCloseModal} center classNames={{
                modal: 'modalContainer'
            }}>
                <div className="containerInscription">
                    <h2>Nouvelle Flotte</h2>
                    <div className='containerInput'>
                        <div className='inputItem' style={{ marginTop: "30px" }}>
                            <label htmlFor="nom">Nom <span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" name="nom" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                        <div className='inputItem' style={{ marginTop: "30px" }}>
                            <label htmlFor="nom">email <span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" name="email" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                    </div>
                    <div className='containerInput'>
                        <div className='inputItem' style={{ marginTop: "30px" }}>
                            <label htmlFor="nom">Phone <span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" name="phone" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                        <div className='inputItem' style={{ marginTop: "30px" }}>
                            <label htmlFor="nom">N° CNI <span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" name="cni" onChange={(e) => handleChange(e)} type="text" />
                        </div>

                    </div>
                    <div className='containerInput'>
                        <div className='inputItem'>
                            <label htmlFor="classw">Nombre de voiture <span>*</span></label>
                            <input placeholder="Enter your text..." className="input" name="nombreVoiture" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                        <div className='inputItem'>
                            <label htmlFor="classw">Localisation <span>*</span> </label>
                            <input placeholder="Enter your text..." accept='image/*' className="input" name="localisation" onChange={(e) => compressImage(e)} type="file" />
                        </div>
                    </div>
                    <div className='containerInput'>
                        <div className='inputItem' >
                            <label htmlFor="nom">Photo Cni arrier <span>*</span> </label>
                            <input placeholder="Enter your text..." accept='image/*' className="input" name="photoCni2" onChange={(e) => compressImage(e)} type="file" />
                        </div>

                        <div className='inputItem' >
                            <label htmlFor="nom">Photo Cni Avant <span>*</span> </label>
                            <input placeholder="Enter your text..." accept='image/*' className="input" name="photoCni1" onChange={(e) => compressImage(e)} type="file" />
                        </div>
                    </div>
                    <div className='containerInput'>
                        <div className='inputItem'>
                            <label htmlFor="classw">Mot de passe <span>*</span></label>
                            <input placeholder="Enter your text..." className="input" name="password" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                        <div className='inputItem'>
                            <label htmlFor="classw">Logo </label>
                            <input placeholder="Enter your text..." accept='image/*' className="input" name="logo" onChange={(e) => compressImage(e)} type="file" />
                        </div>

                    </div>
                    <div className='containerInput'>
                        <div className='inputItem'>
                            <label htmlFor="classw">Nom de l'entreprise <span>*</span></label>
                            <input placeholder="Enter your text..." className="input" name="nomFlotte" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                        <div className='inputItem'>
                            <label htmlFor="classw">Numero d'identifiant unique </label>
                            <input placeholder="Enter your text..."  className="input" name="niu" onChange={(e) => handleChange(e)}  type="text" />
                        </div>

                    </div>
                    <button className="animated-button" onClick={handleSubmit}>
                        <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                            ></path>
                        </svg>
                        {
                            isLoader ?
                                <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                    ></path>
                                </svg>
                                : <span className="text" >Ajouter</span>
                        }

                        <span className="circle"></span>
                        <svg viewBox="0 0 24 24" className="arr-1" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                            ></path>
                        </svg>
                    </button>

                </div>

            </Modal>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false} />
        </div>
    )
}




export default Modale
