import React from 'react'

import "./loader.css"

function Loader() {
    return (
        <div className="loader">
            <div className="loader-bar bar-1"></div>
            <div className="loader-bar bar-2"></div>
            <div className="loader-bar bar-3"></div>
            <div className="loader-bar bar-4"></div>
        </div>

    )
}

export default Loader
