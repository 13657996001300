import axios from 'axios';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import React, { useRef, useState } from 'react'
import Modal from 'react-responsive-modal'
import { ToastContainer, toast } from 'react-toastify';
import imageCompression from 'browser-image-compression';

import { createChauffeur } from '../../../utils/api/api';
import Loader from '../../../components/Loader/Loader';
function Modale({ isOpen, setIsOpen, currentUser, setLoading }) {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyCNJXjPNJI96OQs2Qfin46-Ow7sSeXx8nA",
        libraries: ['places'],
    })
    const [isLoader, setIsLoader] = useState(false)
    const [chauffeur, setChauffeur] = useState([])
    const onCloseModal = () => setIsOpen(false);
    const originRef = useRef()

    const handleChange = (e) => {
        setChauffeur({ ...chauffeur, [e.target.name]: e.target.value })
        // eslint-disable-next-line no-undef
        const geocode = new google.maps.Geocoder()
        geocode.geocode({ address: originRef.current.value }, (results, status) => {
            if (status === 'OK') {
                setChauffeur({
                    ...chauffeur,
                    quartier: originRef.current.value,
                    latitude: results[0].geometry.location.lat(),
                    lontidute: results[0].geometry.location.lng(),
                    [e.target.name]: e.target.value
                })
            }
            else {
                console.log("err")

            }
        })
    }
    const compressImage = async (e) => {
        const file = e.target.files[0]
        const option = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1000
        }
        try {
            const compressFile = await imageCompression(file, option)
            var reader = new FileReader()
            reader.readAsDataURL(compressFile)
            reader.onload = () => {
                setChauffeur({
                    ...chauffeur, [e.target.name]: reader.result
                })
                console.log(reader.result)
            }
        } catch (error) {
            toast.error("le fichier doit etre une image")
            console.log(error);
        }
    }
    const handleSubmit = async () => {
        setIsLoader(true)
        console.log(chauffeur)
        if (!chauffeur.nom || !chauffeur.cni || !chauffeur.dateNaissance || !chauffeur.email || !chauffeur.password
            || !chauffeur.pays || !chauffeur.phone || !chauffeur.photo || !chauffeur.planLocalisation || !chauffeur.quartier
            || !chauffeur.ville || !chauffeur.photoInterieurVoiture || !chauffeur.photoPermis || !chauffeur.photoAilVoiture ||
            !chauffeur.photoArriereVoiture || !chauffeur.photoAvantVoiture || !chauffeur.cniGarrant || !chauffeur.numeroGarrant
            || !chauffeur.photoArriereCni || !chauffeur.photoAvantCni
            || !chauffeur.marque || !chauffeur.imatriculation
        ) {
            toast.error("Veuillez remplir tous les champs")
            setIsLoader(false)
            return
        }
        console.log(currentUser);
        try {
            await axios.post(`${createChauffeur}/${currentUser[0]._id}`, chauffeur, {
                headers: {
                    'Authorization': `Bearer ${currentUser[0].token}`,
                }
            })
            setIsLoader(false)
            setIsOpen(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setIsLoader(false)
        }
    }
    return (
        <div>
            <Modal open={isOpen} onClose={onCloseModal} center classNames={{
                modal: 'modalContainer'
            }}>
                <div className="containerInscription">
                    <h2>Nouveau Chauffeur</h2>
                    {
                        !isLoaded ? <div><Loader/></div> :
                            <>
                                <div className='containerInput'>
                                    <div className='inputItem' style={{ marginTop: "30px" }}>
                                        <label htmlFor="nom">Nom <span>*</span> </label>
                                        <input required placeholder="Enter your text..." className="input" name="nom" onChange={(e) => handleChange(e)} type="text" />
                                    </div>
                                    <div className='inputItem' style={{ marginTop: "30px" }}>
                                        <label htmlFor="cni"> Numéro CNI <span>*</span> </label>
                                        <input required placeholder="Enter your text..." className="input" name="cni" onChange={(e) => handleChange(e)} type="text" />
                                    </div>

                                </div>
                                <div className='containerInput'>
                                    <div className='inputItem' style={{ marginTop: "30px" }}>
                                        <label htmlFor="photoAvantCni">Photo CNI Avant <span>*</span> </label>
                                        <input required accept='image/*' style={{ height: "60px" }} placeholder="Enter your text..." className="input" name="photoAvantCni" onChange={(e) => compressImage(e)} type="file" />
                                    </div>
                                    <div className='inputItem' style={{ marginTop: "30px" }}>
                                        <label htmlFor="photoAvantCni"> Photo CNI Arrière <span>*</span> </label>
                                        <input required accept='image/*' style={{ height: "60px" }} placeholder="Enter your text..." className="input" name="photoArriereCni" onChange={(e) => compressImage(e)} type="file" />
                                    </div>

                                </div>
                                <div className='containerInput'>
                                    <div className='inputItem' style={{ marginTop: "30px" }}>
                                        <label htmlFor="imatriculation">Numéro immatriculation <span>*</span> </label>
                                        <input required placeholder="Enter your text..." className="input" name="imatriculation" onChange={(e) => handleChange(e)} type="text" />
                                    </div>
                                    <div className='inputItem' style={{ marginTop: "30px" }}>
                                        <label htmlFor="marque"> Marque de la voiture<span>*</span> </label>
                                        <input required placeholder="Enter your text..." className="input" name="marque" onChange={(e) => handleChange(e)} type="text" />
                                    </div>

                                </div>
                                <div className='containerInput'>
                                    <div className='inputItem' style={{ marginTop: "30px" }}>
                                        <label htmlFor="numeroGarrant">Numéro de Téléphone d'un garrant <span>*</span> </label>
                                        <input required placeholder="Enter your text..." className="input" name="numeroGarrant" onChange={(e) => handleChange(e)} type="text" />
                                    </div>
                                    <div className='inputItem' style={{ marginTop: "30px" }}>
                                        <label htmlFor="cniGarrant"> Numéro de CNI d'un garrant<span>*</span> </label>
                                        <input required placeholder="Enter your text..." className="input" name="cniGarrant" onChange={(e) => handleChange(e)} type="text" />
                                    </div>

                                </div>
                                <div className='containerInput'>
                                    <div className='inputItem' style={{ marginTop: "30px" }}>
                                        <label htmlFor="photoAvantVoiture">Photo Avant de la voiture <span>*</span> </label>
                                        <input required accept='image/*' style={{ height: "60px" }} placeholder="Enter your text..." className="input" name="photoAvantVoiture" onChange={(e) => compressImage(e)} type="file" />
                                    </div>
                                    <div className='inputItem' style={{ marginTop: "30px" }}>
                                        <label htmlFor="photoArriereVoiture"> Photo arrière de la voiture<span>*</span> </label>
                                        <input required accept='image/*' style={{ height: "60px" }} placeholder="Enter your text..." className="input" name="photoArriereVoiture" onChange={(e) => compressImage(e)} type="file" />
                                    </div>

                                </div>
                                <div className='containerInput'>
                                    <div className='inputItem' style={{ marginTop: "30px" }}>
                                        <label htmlFor="photoInterieurVoiture">Photo  interne de la voiture <span>*</span> </label>
                                        <input required accept='image/*' style={{ height: "60px" }} placeholder="Enter your text..." className="input" name="photoInterieurVoiture" onChange={(e) => compressImage(e)} type="file" />
                                    </div>
                                    <div className='inputItem' style={{ marginTop: "30px" }}>
                                        <label htmlFor="photoAilVoiture"> Photo de l'aile de la voiture<span>*</span> </label>
                                        <input required accept='image/*' style={{ height: "60px" }} placeholder="Enter your text..." className="input" name="photoAilVoiture" onChange={(e) => compressImage(e)} type="file" />
                                    </div>

                                </div>
                                <div className='containerInput'>
                                    <div className='inputItem' style={{ marginTop: "30px" }}>
                                        <label htmlFor="planLocalisation">Plan Localisatoin <span>*</span> </label>
                                        <input required style={{ height: "60px" }} placeholder="Enter your text..." accept='image/*' className="input" name="planLocalisation" onChange={(e) => compressImage(e)} type="file" />
                                    </div>
                                    <div className='inputItem' style={{ marginTop: "30px" }}>
                                        <label htmlFor="photo">Votre Photo <span>*</span> </label>
                                        <input required style={{ height: "60px" }} placeholder="Enter your text..." accept='image/*' className="input" name="photo" onChange={(e) => compressImage(e)} type="file" />
                                    </div>

                                </div>
                                <div className='containerInput'>
                                    <div className='inputItem'>
                                        <label htmlFor="email">email <span>*</span></label>
                                        <input required placeholder="Enter your text..." className="input" name="email" onChange={(e) => handleChange(e)} type="text" />
                                    </div>
                                    <div className='inputItem'>
                                        <label htmlFor="password">password <span>*</span></label>
                                        <input required placeholder="Enter your text..." className="input" name="password" onChange={(e) => handleChange(e)} type="password" />
                                    </div>
                                </div>
                                <div className='containerInput'>
                                    <div className='inputItem'>
                                        <label htmlFor="phone">Phone <span>*</span></label>
                                        <input required placeholder="Enter your text..." className="input" name="phone" onChange={(e) => handleChange(e)} type="text" />
                                    </div>
                                    <div className='inputItem'>
                                        <label htmlFor="ville">Ville <span>*</span></label>
                                        <input required placeholder="Enter your text..." className="input" name="ville" onChange={(e) => handleChange(e)} type="text" />
                                    </div>
                                </div>
                                <div className='containerInput'>
                                    <div className='inputItem'>
                                        <label htmlFor="quartier">Quartier <span>*</span></label>
                                        <Autocomplete>
                                            <input ref={originRef} required placeholder="Enter your text..." className="input" name="quartier" onChange={(e) => handleChange(e)} type="text" />
                                        </Autocomplete>
                                    </div>
                                    <div className='inputItem'>
                                        <label htmlFor="pays">Pays <span>*</span></label>
                                        <input required placeholder="Enter your text..." className="input" name="pays" onChange={(e) => handleChange(e)} type="text" />
                                    </div>
                                </div>
                                <div className='containerInput'>
                                    <div className='inputItem'>
                                        <label htmlFor="photoPermis">Photo du permis de conduire <span>*</span></label>
                                        <input required accept='image/*' style={{ height: "60px" }} placeholder="Enter your text..." className="input" name="photoPermis" onChange={(e) => compressImage(e)} type="file" />
                                    </div>
                                    <div className='inputItem'>
                                        <label htmlFor="dateNaissance">Date de Naissance <span>*</span></label>
                                        <input required placeholder="Enter your text..." className="input" name="dateNaissance" onChange={(e) => handleChange(e)} type="date" />
                                    </div>
                                </div>
                                <button className="animated-button" onClick={handleSubmit}>
                                    <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                        ></path>
                                    </svg>
                                    {
                                        isLoader ?
                                            <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                                ></path>
                                            </svg>
                                            : <span className="text" >Ajouter</span>
                                    }

                                    <span className="circle"></span>
                                    <svg viewBox="0 0 24 24" className="arr-1" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                        ></path>
                                    </svg>
                                </button>
                            </>

                    }

                </div>

            </Modal>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false} />
        </div>
    )
}

export default Modale
