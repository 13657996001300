import axios from 'axios'
import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import { toast, ToastContainer } from 'react-toastify'
import { createAbonnementSchoole } from '../../utils/api/api'

function Modale({ isOpen, setIsOpen, currentUser }) {
    const [abonnement, setAbonnement] = useState([])
    const [isLoader, setIsLoader] = useState(false)
    const onCloseModal = () => setIsOpen(false)
    const handleChange = (e) => {
        setAbonnement({
            ...abonnement,
            validate:true,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async () => {
        setIsLoader(true)
        try {
            const {data} = await axios.post(`${createAbonnementSchoole}/${currentUser._id}`,abonnement)
            console.log(data)
            setIsLoader(false)
            setIsOpen(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setIsLoader(false)
            console.log(error)
        }
    }
    return (
        <div>
            <Modal open={isOpen} onClose={onCloseModal} center classNames={{
                modal: 'modalContainer'
            }}>
                <div className="containerInscription" style={{
                    height:"500px"
                }}>
                    <h2>Nouvelle Abonnement</h2>
                    <div className='containerInput'>
                        <div className='inputItem' style={{ marginTop: "30px" }}>
                            <label htmlFor="nom">Titre Abonnement <span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" name="nom" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                        <div className='inputItem' style={{ marginTop: "30px" }}>
                            <label htmlFor="nom">Prix au Km <span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" name="prix" onChange={(e) => handleChange(e)} type="number" />
                        </div>
                    </div>
                    <div className='containerInput'>
                        <div className='inputItem'>
                            <label htmlFor="classw">nombre de mois <span>*</span></label>
                            <input placeholder="Enter your text..." className="input" name="periodeValide" onChange={(e) => handleChange(e)} type="email" />
                        </div>
                    </div>
                    <button className="animated-button" onClick={handleSubmit} style={{
                        marginTop:"20px"
                    }}>
                        <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                            ></path>
                        </svg>
                        {
                            isLoader ?
                                <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                    ></path>
                                </svg>
                                : <span className="text" >Ajouter</span>
                        }

                        <span className="circle"></span>
                        <svg viewBox="0 0 24 24" className="arr-1" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                            ></path>
                        </svg>
                    </button>

                </div>

            </Modal>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false} />
        </div>
    )
}

export default Modale
