import React, { useEffect, useState } from 'react'
import { Card, Grid } from '@mui/material'

import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar'
import MDBox from '../../components/MDBox'
import authorsTableData from "./data/authorsTableData";
import MDTypography from '../../components/MDTypography'
import DataTable from '../../examples/Tables/DataTable'
import Loader from '../../components/Loader/Loader'
import Footer from '../../examples/Footer'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { getUrgences } from '../../utils/api/api'

function Urgence() {
    const currentUser = useSelector(state=> state.user.value[0])
    const [urgences, setUrgences] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const { columns, rows2 } = authorsTableData(urgences);
    const [loading, setLoading] = useState(true)

    const updateUrgence = async ()=>{
        setLoading(true)
        try {
            const {data} = await axios.get(getUrgences,{
                headers: {
                    'Authorization': `Bearer ${currentUser.token}`
                }
            })
            setUrgences(data)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(()=>{
        updateUrgence()
        // eslint-disable-next-line
    },[])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {
            !loading ?
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="secondary"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Liste des Urgences
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows: rows2[0] }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
                <button onClick={()=>setIsOpen(true)} style={{
                  padding:"15px",
                  marginTop:"20px",
                  cursor:"pointer",
                  backgroundColor:"#000000",
                  color:"#ffffff",
                  borderRadius:"5px",
                  border:"none",
                  outline:"none",
                  fontSize:"15px",
                  fontWeight:"bold",
                  letterSpacing:"1.5px",
                  textTransform:"uppercase",
                  textAlign:"center",
                }}>
                  Ajouter
                </button>
              </Grid> :
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100vw",
              }}>
                <Loader />
              </div>
              
          }

          {/* <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid> */}
        </Grid>
        {/* {
          isOpen && <Modale isOpen={isOpen} setIsOpen={setIsOpen} currentUser={currentUser} setLoading={setLoading}/>
        } */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default Urgence
