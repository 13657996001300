 /**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios"

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";

// Authentication layout components
import BasicLayout from "../components/BasicLayout";

// Images
import bgImage from "../../../assets/images/bg-sign-in-basic.jpeg";

//redurcer
import { connect } from "../../../utils/redurcer/userSlice";
import { loginAdmin } from "../../../utils/api/api";
import Loader from "../../../components/Loader/Loader";

function SigninAuth() {
  const dispatch = useDispatch()
  const navibation = useNavigate()
  const [isLoging, setIsLogin] = useState(false)
  const currentUser = useSelector(state => state.user.value)
  const [rememberMe, setRememberMe] = useState(false);
  const [user, setUser] = useState({})

  const handleChange = (e) => {
    setUser({
      ...user, [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async() => {
    setIsLogin(true)
    if (!user.email || !user.password) {
      toast.error("remplir tous les champs")
      setIsLogin(false)
      return
    }
    try{
        const {data} = await axios.post(loginAdmin,user)
        dispatch(connect(data))
        setIsLogin(false)
    }catch(err){
        console.log(err);
        setIsLogin(false)
        toast.error(err.response.data.message)
    }
    
  }

  useEffect(() => {
    if (currentUser.length) {
      navibation("/")
    }
    // eslint-disable-next-line
  }, [currentUser.length])

  const handleSetRememberMe = () => setRememberMe(!rememberMe);


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="secondary"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Connexion
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" onChange={(e) => handleChange(e)} name="email" label="Email" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" onChange={(e) => handleChange(e)} name="password" label="Password" fullWidth />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Se souvenir de moi
              </MDTypography>
            </MDBox>
            <MDBox onClick={handleSubmit} mt={4} mb={1}>
              <MDButton variant="gradient" color="secondary" fullWidth>
                {
                    isLoging ? <Loader/> :("Connexion")
                }
              </MDButton>
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="secondary"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false} />
    </BasicLayout>
  );
}

export default SigninAuth;
