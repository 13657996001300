import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name:"user",
    initialState:{
        value:[]
    },
   reducers:{
    connect(state,action){
        state.value = []
        state.value.push(action.payload)
    },
    disconnect(state){
        state.value.pop()
    }
   }
})
console.log(userSlice);

export const {connect, disconnect} = userSlice.actions;

export default userSlice.reducer