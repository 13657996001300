import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import { toast, ToastContainer } from 'react-toastify';
import imageCompression from 'browser-image-compression';

import Loader from '../../../../components/Loader/Loader';
import { useRef } from 'react';
import axios from 'axios';
import { addNewChild } from '../../../../utils/api/api';
function Modale({ isOpen, setIsOpen, abonnements, currentUser }) {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyCNJXjPNJI96OQs2Qfin46-Ow7sSeXx8nA",
        libraries: ['places'],
    })
    // console.log(currentUser)
    // eslint-disable-next-line
    const [isLoader, setIsLoader] = useState(false)
    const [distance, setDistance] = useState(0)
    const [child, setChild] = useState([])
    const originRef = useRef()
    const [directionsResponse, setDirectionsResponse] = useState(null)
    const [duration, setDuration] = useState('')
    const onCloseModal = () => setIsOpen(false);


    const calculePrix = (distance, prix) => {
        console.log(distance)
        const ecart = 4 - distance
        let prixPayer
        if (ecart > 0) {
            prixPayer = distance * (prix * (1 + (ecart / 10)))
            if (prixPayer < 12500) prixPayer = 12500
            console.log("eccart>0", prixPayer)

        }
        if (ecart === 0) return distance * prix
        if (ecart < 0) {
            prixPayer = distance * prix * (1 - (distance / (distance + 100)))
        }
        return prixPayer
    }

    // console.log(abonnements)
    const handleChange = (e) => {


        setChild({
            ...child,
            [e.target.name]: e.target.value,
            prix: null
        })

        // eslint-disable-next-line no-undef
        const geocode = new google.maps.Geocoder()
        geocode.geocode({ address: originRef.current.value }, (results, status) => {
            if (status === 'OK') {
                setChild({
                    ...child,
                    localisation: originRef.current.value,
                    latitude: results[0].geometry.location.lat(),
                    lontidute: results[0].geometry.location.lng(),
                    [e.target.name]:e.target.value
                })
              

                
            }
            else {
                console.log("err")

            }
        })
    }
    const handleSubmit = async () => {
        setIsLoader(true)
        if (!child.prix) {
            toast.error("veiller choisir un point de ramassage")
            return
        }
        try {
            const newChild = {
                ...child,
                ramassage: {
                    latitude: child.latitude,
                    lontidute: child.lontidute
                },
                lieudepot: {
                    latitude: child.latitude,
                    lontidute: child.lontidute
                }
            }
            const { data } = await axios.post(`${addNewChild}/${currentUser._id}`, newChild, {
                headers: {
                    'Authorization': `Bearer ${currentUser.token}`
                }
            })
            setIsLoader(false)
            setIsOpen(false)
            console.log(data)
        } catch (error) {
            toast.error(error.response.data.message)
            setIsLoader(false)
            console.log(error)
        }
    }
    const compressImage = async (e) => {
        const file = e.target.files[0]
        const option = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1000
        }
        try {
            const compressFile = await imageCompression(file, option)
            var reader = new FileReader()
            reader.readAsDataURL(compressFile)
            reader.onload = () => {
                setChild({
                    ...child, [e.target.name]: reader.result
                })
            }
        } catch (error) {
            toast.error("le fichier doit etre une image")
            console.log(error);
        }
    }
    async function calculateRoute() {
        setIsLoader(true)
        console.log(child)
        if (!child.nom || !child.adresse || !child.email || !child.phone || !child.phone2 || !child.photo || !child.abonnements) {
            toast.error("Remplir tous les champs")
            setIsLoader(false)
            return
        }

        if (originRef.current.value === '' || currentUser.nomEcole === '') {
            setIsLoader(false)
            return
        }
        try {
            // eslint-disable-next-line no-undef
            const geocode = new google.maps.Geocoder()
            geocode.geocode({ address: originRef.current.value }, (results, status) => {
                if (status === 'OK') {
                    setChild({
                        ...child,
                        localisation: originRef.current.value,
                        latitude: results[0].geometry.location.lat(),
                        lontidute: results[0].geometry.location.lng(),
                    })
                    const destinaion = {
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng()
                    }
                    if (!destinaion.lat || !destinaion.lng) {
                        toast.error("choississez un point de ramassage")
                        setIsLoader(false)
                        return
                    }

                    // eslint-disable-next-line no-undef
                    const service = new google.maps.DistanceMatrixService()
                    const origin = {
                        lat: Number(currentUser.latitude),
                        lng: Number(currentUser.longitude)
                    }

                    console.log(origin)
                    console.log(destinaion)
                    service.getDistanceMatrix({
                        origins: [origin],
                        destinations: [destinaion],
                        // eslint-disable-next-line no-undef
                        travelMode: google.maps.TravelMode.DRIVING,
                    }, (response, status) => {
                        if (status === "OK") {
                            console.log(response)
                            const durer = response.rows[0].elements[0].duration.text
                            console.log(durer)
                            const distance2 = parseFloat(response.rows[0].elements[0].distance.text)
                            setDistance(distance2)
                            const abonnement = abonnements.find(item => item._id === child.abonnements)
                            setChild({
                                ...child,
                                distance: distance2,
                                prix: calculePrix(distance2, Number(abonnement.prix))
                            })
                            console.log(child)
                            console.log(distance2 / 1000)
                            setIsLoader(false)
                        } else {
                            console.log("err")
                        }
                    })
                    setIsLoader(false)
                }
                else {
                    console.log("err")

                }
            })





            setIsLoader(false)

            // setDistance(distance2 / 1000)
        } catch (error) {
            console.log(error)
        }

    }
    if (!isLoaded) {
        return <Loader />
    }
    return (
        <div>
            <Modal open={isOpen} onClose={onCloseModal} center classNames={{
                modal: 'modalContainer'
            }}>
                <div className="containerInscription">
                    <h2>Nouvel Eleve</h2>
                    <div className='containerInput'>
                        <div className='inputItem' style={{ marginTop: "30px" }}>
                            <label htmlFor="nomEcole">Nom Ecole <span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" disabled={true} name="nomEcole" value={currentUser.nomEcole} type="text" />
                        </div>
                        <div className='inputItem' style={{ marginTop: "30px" }}>
                            <label htmlFor="nom">Nom de l'enfant <span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" name="nom" onChange={(e) => handleChange(e)} type="text" />
                        </div>

                    </div>
                    <div className='containerInput'>
                        <div className='inputItem' >
                            <label htmlFor="nom">Quartier <span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" name="adresse" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                        <div className='inputItem' >
                            <label htmlFor="nom">Point de Ramassage <span>*</span> </label>
                            <Autocomplete>
                                <input placeholder="Enter your text..." ref={originRef} className="input" name="adresse" onChange={(e) => handleChange(e)} type="text" />
                            </Autocomplete>
                        </div>

                    </div>
                    <div className='containerInput'>
                        <div className='inputItem' >
                            <label htmlFor="Class">Class <span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" name="class" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                        <div className='inputItem'>
                            <label htmlFor="Date">Date de Naissance <span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" name="dateNaissance" onChange={(e) => handleChange(e)} type="date" />
                        </div>
                    </div>
                    <div className='containerInput'>
                        <div className='inputItem' >
                            <label htmlFor="Heure">Heure d'entrée <span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" name="heureDepart" onChange={(e) => handleChange(e)} type="time" />
                        </div>
                        <div className='inputItem'>
                            <label htmlFor="Date">Heure de sortie <span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" name="heureSortie" onChange={(e) => handleChange(e)} type="time" />
                        </div>
                    </div>
                    <div className='containerInput'>
                        <div className='inputItem'>
                            <label htmlFor="email">email Parent <span>*</span></label>
                            <input placeholder="Enter your text..." className="input" name="email" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                        <div className='inputItem'>
                            <label htmlFor="Phone">N° de téléphone portable <span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" name="phone" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                    </div>
                    <div className='containerInput'>
                        <div className='inputItem'>
                            <label htmlFor="Phone">Autre Numéro<span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" name="phone2" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                        <div className='inputItem'>
                            <label htmlFor="Photo">Photo de l'enfant<span>*</span> </label>
                            <input placeholder="Enter your text..." style={{ height: "50px" }} className="input" accept='image/*' name="photo" onChange={(e) => compressImage(e)} type="file" />
                        </div>
                    </div>
                    <div className='containerInput'>
                        <div className='inputItem'>
                            <label htmlFor="Abonnement">Abonnement<span>*</span> </label>
                            <select onChange={(e) => handleChange(e)} name="abonnements" id="">
                                <option value="">Choisir un abonnement</option>
                                {
                                    abonnements.map(item => {
                                        return (
                                            <option className='input' key={item._id} value={item._id}>{item.nom}</option>
                                        )
                                    })
                                }
                            </select>


                        </div>
                        <div className='inputItem'>
                            <label htmlFor="Prix">Prix  à Payer<span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" disabled={true} name="prix" value={child.prix} type="number" />
                        </div>
                    </div>
                    {!child.prix && <button className="animated-button" onClick={calculateRoute}>
                        <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                            ></path>
                        </svg>
                        {
                            isLoader ?
                                <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                    ></path>
                                </svg>
                                : <span className="text" >Calculer le prix</span>
                        }

                        <span className="circle"></span>
                        <svg viewBox="0 0 24 24" className="arr-1" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                            ></path>
                        </svg>
                    </button>}
                    {
                        child.prix &&
                        <button className="animated-button" onClick={handleSubmit}>
                            <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                ></path>
                            </svg>
                            {
                                isLoader ?
                                    <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                        ></path>
                                    </svg>
                                    : <span className="text" >Enregistrer</span>
                            }

                            <span className="circle"></span>
                            <svg viewBox="0 0 24 24" className="arr-1" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                ></path>
                            </svg>
                        </button>
                    }

                </div>

            </Modal>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false} />
        </div>
    )
}

export default Modale
