import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar'
import MDBox from '../../components/MDBox'
import { Card, Grid, Switch } from '@mui/material'
import MDTypography from '../../components/MDTypography'
import DataTable from '../../examples/Tables/DataTable'
import Loader from '../../components/Loader/Loader'
import { useNavigate, useParams } from 'react-router-dom'
import authorsTableData from "./data/authorsTableData"
import axios from 'axios'
import { chauffeurs, deleteDriver, getOneFlot, updateStatus } from '../../utils/api/api'
import Footer from '../authentication/components/Footer'
import Modale from './modal/Modal'
import { useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'

function Voitures() {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [children, setChildren] = useState([])
  const [flot, setFlot] = useState([])
  const currentUser = useSelector(state => state.user.value)
  const [isOpen, setIsOpen] = useState(false)
  const [delet,setDelet] = useState(false)
  const { idFlote } = useParams()

  const setStatu = async (id) => {
    setLoading(true)
    try {
      await axios.get(`${updateStatus}/${id}`, {
        headers: {
          'Authorization': `Bearer ${currentUser[0].token}`
        }
      })
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }

  }


  useEffect(() => {
    if (!currentUser.length) {
      navigate("/authentication/sign-in")
    }
    // eslint-disable-next-line
  }, [currentUser.length])
  const setAllDriver = async () => {
    if (idFlote) {
      try {
        const { data } = await axios.get(`${getOneFlot}/${idFlote}`, {
          headers: {
            'Authorization': `Bearer ${currentUser[0].token}`
          }
        })
        setFlot(data)
        console.log(data)
        setChildren(data.chauffeurs)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
      return
    }
    try {
      const { data } = await axios.get(`${chauffeurs}/${currentUser[0]._id}`, {
        headers: {
          'Authorization': `Bearer ${currentUser[0].token}`
        }
      })
      setChildren(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const deleteDriverItem = async(id)=>{
    setDelet(true)
    try {
      await axios.get(`${deleteDriver}/${id}`,{
        headers:{
          "Authorization": `Bearer ${currentUser[0].token}`
        }
      })
      setAllDriver()
      setDelet(false)
      toast.success("Chauffeur supprimé")
    } catch (error) {
      setDelet(false)
      if(error.response){
        toast.error(error.response.data.message)
        return
      }
      toast.error("une erreur est survenue")
    }
  }

  useEffect(() => {
    setAllDriver()
    // eslint-disable-next-line
  }, [loading, isOpen])
  const { columns, rows2 } = authorsTableData(children, setStatu, deleteDriverItem, delet);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {
            !loading ?
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="secondary"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography style={{display:"flex"}} variant="h6" color="white">
                      Liste des Voitures
                        { idFlote? 
                      <div style={{
                        width:"70%",
                        display:"flex",
                        justifyContent: "space-between",
                      }}> &nbsp;de <span style={{
                        color: "#000000",
                        fontWeight: "bold",
                        fontSize: "15px"
                      }}> {flot?.nom} </span> 
                        <Switch checked={flot.status} />
                      </div> : ""}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows: rows2[0] }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
                <button onClick={() => setIsOpen(true)} style={{
                  padding: "15px",
                  marginTop: "20px",
                  cursor: "pointer",
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  borderRadius: "5px",
                  border: "none",
                  outline: "none",
                  fontSize: "15px",
                  fontWeight: "bold",
                  letterSpacing: "1.5px",
                  textTransform: "uppercase",
                  textAlign: "center",
                  marginBottom: "100px"
                }}>
                  Ajouter
                </button>
              </Grid> :
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100vw",
              }}>
                <Loader />
              </div>

          }


        </Grid>
        {
          isOpen && <Modale isOpen={isOpen} setIsOpen={setIsOpen} currentUser={currentUser} setLoading={setLoading} />
        }
      </MDBox>
      <ToastContainer/>
      <Footer />
    </DashboardLayout>
  )
}

export default Voitures
