import axios from 'axios'
import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import { toast, ToastContainer } from 'react-toastify'
import { addChildBus, addDriverChild, childrenOneSchool, getAllChildren } from '../../utils/api/api'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import Loader from '../../components/Loader/Loader'
import { element } from 'prop-types'

function Modale({ isOpen, children, user, setIsOpen, id }) {

    const currentUser = useSelector(state => state.user.value)
    const [isLoader, setIsLoader] = useState(false)
    const [loading, setLoading] = useState(true)
    const onCloseModal = () => setIsOpen(false)
    const [child, setChild] = useState([])
    const [selectChild, setSelectChild] = useState([])

    const url = window.location.href

    const updateChild = async () => {
        try {
            if (url.includes("ecole/car")) {
                const { data } = await axios.get(`${childrenOneSchool}/${currentUser[0]._id}`, {
                    headers: {
                        'Authorization': `Bearer ${currentUser[0].token}`
                    }
                })
                let dataChild = []
                console.log(data)
                data.forEach(element => {
                    console.log(element);
                    if ((!element.bus && !element.chauffeur) || (element.bus === null && element.chauffeur === null)) {
                        dataChild.push(element)
                    }
                });
                setChild(dataChild)
                console.log(data)
                setLoading(false)
                return
            }
            if (user.enfants[0]) {
                let dataChild = []
                children.map(element => {
                    if (user.enfants[0].ecole === element.ecole._id) {
                        dataChild.push(element)
                    }
                })
                setChild(dataChild)
                return
            }
            console.log(children)
            setChild(children)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        updateChild()
        // eslint-disable-next-line
    }, [isOpen])
    const handleChange = (e) => {
        console.log(e.target.value)
        const childSelect = child.filter(item => item._id === e.target.value)
        console.log(childSelect)
        setSelectChild(childSelect)
    }
    const handleSubmit = async (idEnfant) => {
        console.log(idEnfant)
        if (user.enfants[0]) {
            if (user.enfants[0].ecole !== selectChild[0].ecole._id) {
                toast.error("vous avez des enfants qui ne vont dans cette école")
                return
            }
        }

        setIsLoader(true)
        try {
            if (url.includes("ecole/car")) {
                const { data } = await axios.post(`${addChildBus}/${id}`, { idEnfant: idEnfant })
                setIsOpen(false)
                console.log(data)
                return
            }
            const { data } = await axios.post(`${addDriverChild}/${id}`, { idEnfant: idEnfant }, {
                headers: {
                    'Authorization': `Bearer ${currentUser[0].token}`
                }
            })
            setIsOpen(false)
            setIsLoader(false)
            console.log(data)
            return

        } catch (err) {
            toast.error(err.response.data.message)
            setIsLoader(false)
            console.log(err)
        }
    }
    return (
        <div>

            <Modal open={isOpen} onClose={onCloseModal} center classNames={{
                modal: 'modalContainer'
            }}>
                {
                    loading ? <div style={{
                        height: "60vh"
                    }} className="containerInscription">
                        <Loader />
                    </div> :
                        <div style={{
                            height: "60vh"
                        }} className="containerInscription">
                            <h2>Ajouter un nouvelle élève</h2>
                            <div className='containerInput'>
                                <div className='inputItem' style={{ marginTop: "30px" }}>
                                    <label htmlFor="nom">Nom <span>*</span> </label>
                                    <select style={{ height: "45px" }} className='input' name="nom" id="nom" onChange={(e) => { handleChange(e) }}>
                                        <option value="">Choisir un enfant</option>
                                        {
                                            child.map(item => {
                                                return (
                                                    <option style={{ height: "60px" }} className="input" key={item._id} value={item._id}>{item.nom}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className='inputItem' style={{ marginTop: "30px" }}>
                                    <label htmlFor="nom">Class <span>*</span> </label>
                                    <input required placeholder="Enter your text..." disabled={true} className="input" name="email" value={selectChild[0]?.class} />
                                </div>

                            </div>
                            <div className='containerInput'>
                                <div className='inputItem'>
                                    <label htmlFor="classw">Date de Naissance <span>*</span></label>
                                    <input required placeholder="Enter your text..." disabled={true} className="input" name="matricule" value={selectChild[0]?.dateNaissance} type="text" />
                                </div>
                                <div className='inputItem'>
                                    <label htmlFor="classw">Heure de depart <span>*</span></label>
                                    <input required placeholder="Enter your text..." disabled={true} className="input" value={selectChild[0]?.heureDepart} />
                                </div>
                            </div>
                            <div className='containerInput'>
                                <div className='inputItem'>
                                    <label htmlFor="classw">Heure de sortie <span>*</span></label>
                                    <input required placeholder="Enter your text..." disabled={true} className="input" name="places" value={selectChild[0]?.heureSortie} type="text" />
                                </div>
                                <div className='inputItem'>
                                    <label htmlFor="classw">Disatance en km <span>*</span></label>
                                    <input required placeholder="Enter your text..." disabled={true} className="input" name="cni" value={selectChild[0]?.distance} type="text" />
                                </div>
                            </div>
                            <div className='containerInput'>
                                <div className='inputItem'>
                                    <label htmlFor="classw">photo <span>*</span></label>
                                    <input required placeholder="Enter your text..." disabled={true} className="input" name="phone" value={selectChild[0]?.photo} type="text" />
                                </div>
                                <div className='inputItem'>
                                    <label htmlFor="classw">Ecole <span>*</span></label>
                                    <input required placeholder="Enter your text..." disabled={true} className="input" name="phone" value={selectChild[0]?.ecole.adresse} type="text" />
                                </div>

                            </div>
                            <div className='containerInput' >
                                <div className='inputItem' style={{ width: "100%" }}>
                                    <label htmlFor="classw">Quartier <span>*</span></label>
                                    <input required placeholder="Enter your text..." disabled={true} className="input" name="phone" value={selectChild[0]?.localisation} type="text" />
                                </div>
                                <div className='inputItem' style={{ width: "100%" }}>
                                    <label htmlFor="classw">prix <span>*</span></label>
                                    <input required placeholder="Enter your text..." disabled={true} className="input" name="phone" value={selectChild[0]?.prix} type="text" />
                                </div>
                            </div>

                            <button className="animated-button" onClick={() => handleSubmit(selectChild[0]._id)}>
                                <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                    ></path>
                                </svg>
                                {
                                    isLoader ?
                                        <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                            ></path>
                                        </svg>
                                        : <span className="text" >Ajouter</span>
                                }

                                <span className="circle"></span>
                                <svg viewBox="0 0 24 24" className="arr-1" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                    ></path>
                                </svg>
                            </button>

                        </div>
                }


            </Modal>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false} />
        </div>
    )
}

export default Modale
