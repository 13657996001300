// export const OlinURL = "http://localhost:5000"
export const OlinURL = "https://r2sbackend.onrender.com"

//api all user
export const loginUser = `${OlinURL}/user/login`

//api de l'administrateur
export const singUpAdmin = `${OlinURL}/admin/create`
export const loginAdmin = `${OlinURL}/admin/login`
export const getOntAdmin = `${OlinURL}/admin/profile`
export const getUrgences = `${OlinURL}/admin/urgence`

//api des flottes
export const flots = `${OlinURL}/flote/getAllFlots`
export const createFlot = `${OlinURL}/flote/create`
export const updatePasswordFlot = `${OlinURL}/flote/updatePasswordFlot`
export const getOneFlot = `${OlinURL}/flote/getOneFlot`
export const getDriverOneFlotte = `${OlinURL}/flote/getDriverOneFlotte`


//api des enfants
export const childrenOneSchool = `${OlinURL}/enfant/childrenSchool`
export const getAllChildren = `${OlinURL}/enfant/allChildren`
export const deleteChild = `${OlinURL}/enfant/deleteChild`


//api de l'école
export const getOneSchool = `${OlinURL}/ecole/getOneSchool`
export const souscribeBus = `${OlinURL}/ecole/signBus`
export const createBus = `${OlinURL}/ecole/createBus`
export const ecoles = `${OlinURL}/ecole/getSchools`
export const createSchool = `${OlinURL}/ecole/addSchool`
export const getAllBus = `${OlinURL}/ecole/getAllbus`
export const getOneBus = `${OlinURL}/ecole/getOneBus`
export const addChildBus = `${OlinURL}/ecole/addBusChild`
export const deleteChildBus = `${OlinURL}/ecole/deleteChildBus` 
export const createAbonnementSchoole = `${OlinURL}/ecole/createAbonnement`
export const getAllAbonnementsSchool = `${OlinURL}/ecole/getAllAbonnements`
export const getAbonnementSchoole = `${OlinURL}/ecole/getAbonnement`
export const addNewChild = `${OlinURL}/ecole/addNewChild`
export const getChildrenBus = `${OlinURL}/ecole/getChildrenOneBus`
export const getChildTransportToday = `${OlinURL}/ecole/enfantsTransporter`
export const updateUserAbonnement = `${OlinURL}/ecole/updateUserAbonnement`

//api du chauffeur
export const chauffeurs = `${OlinURL}/chauffeur/chauffeurFlot`
export const createChauffeur = `${OlinURL}/chauffeur/create`
export const getChildsOneDriver = `${OlinURL}/chauffeur/getEnfants`
export const updateStatus = `${OlinURL}/chauffeur/updateStatus`
export const getAllDriver = `${OlinURL}/chauffeur/allChauffeurs`
export const getOneDriver = `${OlinURL}/chauffeur/oneChauffeur`
export const addDriverChild = `${OlinURL}/chauffeur/addNewchild`
export const deletDriverChild = `${OlinURL}/chauffeur/deleteChild`
export const deleteDriver = `${OlinURL}/chauffeur/deleteDriver`