import React, { useRef } from 'react'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal'
import { useState } from 'react';
import imageCompression from 'browser-image-compression';
// import { useSelector } from "react-redux"
import { ToastContainer, toast } from 'react-toastify';
// import { Document, Page, Text, View, Table } from '@react-pdf/renderer';


import "./modal.css"
import axios from 'axios';
import { createSchool } from '../../utils/api/api';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import Loader from '../../components/Loader/Loader';




function Modale({ isOpen, setIsOpen, currentUser, setLoading }) {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyCNJXjPNJI96OQs2Qfin46-Ow7sSeXx8nA",
        libraries: ['places'],
    })
    console.log(currentUser);
    const [school, setSchool] = useState([])
    const [isLoader, setIsLoader] = useState(false)
    const onCloseModal = () => setIsOpen(false);
    const [position, setPosidtion] = useState(false)
    const originRef = useRef()


    const handleChange = (e) => {
        console.log(school)
        setSchool({
            ...school,
            [e.target.name]: e.target.value
        })



    }
    const compressImage = async (e) => {
        const file = e.target.files[0]
        const option = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1000
        }
        try {
            const compressFile = await imageCompression(file, option)
            var reader = new FileReader()
            reader.readAsDataURL(compressFile)
            reader.onload = () => {
                setSchool({
                    ...school, [e.target.name]: reader.result
                })
            }
        } catch (error) {
            toast.error("le fichier doit etre une image")
            console.log(error);
        }
    }
    const checkLocalisation = () => {
        // eslint-disable-next-line no-undef
        const geocode = new google.maps.Geocoder()
        geocode.geocode({ address: originRef.current.value }, (results, status) => {
            if (status === 'OK') {
                setSchool({
                    ...school,
                    adresse: originRef.current.value,
                    latitude: results[0].geometry.location.lat(),
                    longitude: results[0].geometry.location.lng()
                })
                setPosidtion(true)
            }
            else {
                toast.error("L'école n'est géolocaliser sur google map. Veiller indiquer une position proche de ")

            }
        })
    }
    const handleSubmit = async () => {
        console.log(originRef.current.value)
        setIsLoader(true)

        console.log(school)
        if (!school.nomEcole || !school.adresse || !school.email || !school.phone || !school.password || !school.bp || !school.ville || !school.photo) {
            toast("veiller remplir tous les champs")
            setIsLoader(false)
            return
        }
        if (!school.latitude || !school.longitude) {
            toast.error("L'école n'est géolocaliser sur google map. Veiller indiquer une position proche de ")
            setIsLoader(false)
            return
        }

        try {
            const { data } = await axios.post(`${createSchool}/${currentUser[0].id}`,
                school,
                {
                    headers: {
                        'Authorization': `Bearer ${currentUser[0].token}`
                    }
                }
            )
            setIsLoader(false)
            setIsOpen(false)
            setLoading(true)
        } catch (error) {
            setIsLoader(false)
            toast.error(error.response.data.message)
            console.log(error);
        }

    }

    if (!isLoaded) {
        return <Loader />
    }

    return (
        <div>
            <Modal open={isOpen} onClose={onCloseModal} center classNames={{
                modal: 'modalContainer'
            }}>
                <div className="containerInscription">
                    <h2>Nouvelle Ecole</h2>
                    <div className='containerInput'>
                        <div className='inputItem' style={{ marginTop: "30px" }}>
                            <label htmlFor="nom">Nom Ecole <span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" name="nomEcole" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                        <div className='inputItem' style={{ marginTop: "30px" }}>
                            <label htmlFor="nom">Localisatoin <span>*</span> </label>
                            <Autocomplete>
                                <input placeholder="Enter your text..." ref={originRef} className="input" name="adresse" onChange={(e) => handleChange(e)} type="text" />
                            </Autocomplete>
                        </div>
                    </div>
                    <div className='containerInput'>
                        <div className='inputItem'>
                            <label htmlFor="classw">email <span>*</span></label>
                            <input placeholder="Enter your text..." className="input" name="email" onChange={(e) => handleChange(e)} type="email" />
                        </div>
                        <div className='inputItem'>
                            <label htmlFor="classw">N° de téléphone portable <span>*</span> </label>
                            <input placeholder="Enter your text..." className="input" name="phone" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                    </div>
                    <div className='containerInput'>
                        <div className='inputItem'>
                            <label htmlFor="classw">Mot de passe <span>*</span></label>
                            <input placeholder="Enter your text..." className="input" name="password" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                        <div className='inputItem'>
                            <label htmlFor="classw">Boite Postal <span>*</span></label>
                            <input placeholder="Enter your text..." className="input" name="bp" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                    </div>
                    <div className="containerInput">
                        <div className="inputItem">
                            <label htmlFor="classw">Ville <span>*</span></label>
                            <input placeholder="Enter your text..." className="input" name="ville" onChange={(e) => handleChange(e)} type="text" />
                        </div>
                        <div className="inputItem">
                            <label htmlFor="classw">Logo <span>*</span></label>
                            <input placeholder="Enter your text..." className="input" accept='image/*' name="photo" onChange={(e) => compressImage(e)} type="file" />
                        </div>
                    </div>
                    {
                        !position ? <button className="animated-button" onClick={checkLocalisation}>
                            <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                ></path>
                            </svg>
                            {
                                <span className="text" >vérification de position</span>
                            }

                            <span className="circle"></span>
                            <svg viewBox="0 0 24 24" className="arr-1" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                ></path>
                            </svg>
                        </button> :
                            <button className="animated-button" onClick={handleSubmit}>
                                <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                    ></path>
                                </svg>
                                {
                                    isLoader ?
                                        <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                            ></path>
                                        </svg>
                                        : <span className="text" >Ajouter</span>
                                }

                                <span className="circle"></span>
                                <svg viewBox="0 0 24 24" className="arr-1" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                    ></path>
                                </svg>
                            </button>
                    }

                </div>

            </Modal>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false} />
        </div>
    )
}




export default Modale
