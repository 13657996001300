/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
// import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import ProfileInfoCard from "../../examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "../../examples/Lists/ProfilesList";
// import DefaultProjectCard from "../../examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "./components/Header";
import PlatformSettings from "./components/PlatformSettings";

// Data
import profilesListData from "./data/profilesListData";

// Images

import { useSelector } from "react-redux";
import axios from "axios";
import { getOneFlot, getOneSchool, getOntAdmin, updateStatus } from "../../utils/api/api";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import { useEffect, useState } from "react";

function Overview() {
  const currentUser = useSelector(state => state.user.value[0])
  console.log(currentUser)
  const [user, setUser] = useState({});
  const [isOpen, setIsOpen] = useState(true)
  
  const setStatu = async (id) => {
    setIsOpen(true)
    try {
      await axios.get(`${updateStatus}/${id}`, {
        headers: {
          'Authorization': `Bearer ${currentUser.token}`
        }
      })
      setIsOpen(false)
    } catch (error) {
      console.log(error);
      setIsOpen(false)
    }

  }

  const getUser = async () => {
    try {
      if (currentUser.role === 1) {
        const { data } = await axios.get(`${getOneFlot}/${currentUser._id}`)
        setUser(data)
        setIsOpen(false)
        return
      }
      if (currentUser.role === 3) {
        const { data } = await axios.get(`${getOntAdmin}/${currentUser.id}`, {
          headers: {
            'Authorization': `Bearer ${currentUser.token}`
          }
        })
        setUser(data)
        setIsOpen(false)
        return
      }
      if (currentUser.role === 2) {
        const { data } = await axios.get(`${getOneSchool}/${currentUser._id}`)
        setUser(data)
        setIsOpen(false)
        return
      }

    } catch (error) {
      toast.error("une erreur est survenue veiler reessayer")
      setIsOpen(false)
      console.log(error)
    }
  }
  useEffect(() => {
    getUser()
    // eslint-disable-next-line
  }, [isOpen])
  return (
    <DashboardLayout>
        {console.log(currentUser)}
      <DashboardNavbar />
      <MDBox mb={2} />
      {
        isOpen ?
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
          }}>
            <Loader />
          </div>
          :
          <Header currentUser={user}>
            <MDBox mt={5} mb={3}>
              <Grid container spacing={1}>

                <Grid item xs={12} md={6} xl={4}>
                  <ProfileInfoCard
                    title="Informations personnelles"
                    info={{
                      Nom: user.nom ? user.nom : user.nomEcole,
                      mobile: user?.phone,
                      email: user?.email,
                      location: user.ville ? user.ville: user.adresse,
                      description:""
                    }}
                    social={[
                      {
                        link: "#",
                        icon: <FacebookIcon />,
                        color: "facebook",
                      },
                      {
                        link: "#",
                        icon: <TwitterIcon />,
                        color: "twitter",
                      },
                      {
                        link: "#",
                        icon: <InstagramIcon />,
                        color: "instagram",
                      },
                    ]}
                    action={{ route: "", tooltip: "Edit Profile" }}
                    shadow={false}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
                  <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />

                  <PlatformSettings currentUser={user} />
                  {currentUser.role === 1 && <Divider orientation="vertical" sx={{ mx: 0 }} />}
                </Grid>
                {currentUser.role === 1 && <Grid item xs={12} xl={4}>
                  <ProfilesList title="Quelques Chauffeurs" chauffeurs={user.chauffeurs} setStatu={setStatu} profiles={profilesListData} shadow={false} />
                </Grid>}
              </Grid>
            </MDBox>
            {/* <MDBox pt={2} px={2} lineHeight={1.25}>
              <MDTypography variant="h6" fontWeight="medium">
                Projects
              </MDTypography>
              <MDBox mb={1}>
                <MDTypography variant="button" color="text">
                  Architects design houses
                </MDTypography>
              </MDBox>
            </MDBox> */}
            {/* <MDBox p={2}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={homeDecor1}
                    label="project #2"
                    title="modern"
                    description="As Uber works through a huge amount of internal management turmoil."
                    action={{
                      type: "internal",
                      route: "/pages/profile/profile-overview",
                      color: "info",
                      label: "view project",
                    }}
                    authors={[
                      { image: team1, name: "Elena Morison" },
                      { image: team2, name: "Ryan Milly" },
                      { image: team3, name: "Nick Daniel" },
                      { image: team4, name: "Peterson" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={homeDecor2}
                    label="project #1"
                    title="scandinavian"
                    description="Music is something that everyone has their own specific opinion about."
                    action={{
                      type: "internal",
                      route: "/pages/profile/profile-overview",
                      color: "info",
                      label: "view project",
                    }}
                    authors={[
                      { image: team3, name: "Nick Daniel" },
                      { image: team4, name: "Peterson" },
                      { image: team1, name: "Elena Morison" },
                      { image: team2, name: "Ryan Milly" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={homeDecor3}
                    label="project #3"
                    title="minimalist"
                    description="Different people have different taste, and various types of music."
                    action={{
                      type: "internal",
                      route: "/pages/profile/profile-overview",
                      color: "info",
                      label: "view project",
                    }}
                    authors={[
                      { image: team4, name: "Peterson" },
                      { image: team3, name: "Nick Daniel" },
                      { image: team2, name: "Ryan Milly" },
                      { image: team1, name: "Elena Morison" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={homeDecor4}
                    label="project #4"
                    title="gothic"
                    description="Why would anyone pick blue over pink? Pink is obviously a better color."
                    action={{
                      type: "internal",
                      route: "/pages/profile/profile-overview",
                      color: "info",
                      label: "view project",
                    }}
                    authors={[
                      { image: team4, name: "Peterson" },
                      { image: team3, name: "Nick Daniel" },
                      { image: team2, name: "Ryan Milly" },
                      { image: team1, name: "Elena Morison" },
                    ]}
                  />
                </Grid>
              </Grid>
            </MDBox> */}
          </Header>
      }
      <ToastContainer />
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
