import React, { useEffect, useRef, useState } from 'react'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { onValue, ref, set, getDatabase } from 'firebase/database';
import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    HStack,
    IconButton,
    Input,
    Text,
} from '@chakra-ui/react'
import { FaCar, FaLocationArrow, FaTimes } from 'react-icons/fa'
import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    Autocomplete,
    DirectionsRenderer,
} from '@react-google-maps/api'
import Loader from '../../components/Loader/Loader'
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar'
import Footer from '../../examples/Footer'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getChildrenBus, getChildsOneDriver, OlinURL } from '../../utils/api/api';

import taxi from "../../assets/images/taxi.jpg"
import eleve from "../../assets/images/eleve.jpg"
import { useSelector } from 'react-redux';

const firebaseConfig = {
    apiKey: "AIzaSyBZ9sJZeji6lYLbvkstmJLFkss7bI1Uhc8",
    authDomain: "ride-2-school.firebaseapp.com",
    databaseURL: "https://ride-2-school-default-rtdb.firebaseio.com",
    projectId: "ride-2-school",
    storageBucket: "ride-2-school.appspot.com",
    messagingSenderId: "309273170745",
    appId: "1:309273170745:web:6d5cbe5c640e3aa4d58ee8",
    measurementId: "G-W0CRK6V93C"
};



const MyMap = () => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyCNJXjPNJI96OQs2Qfin46-Ow7sSeXx8nA",
        libraries: ['places'],
    })
    const currentUser = useSelector(state => state.user.value[0])
    const [loading, setLoading] = useState(true)
    const { id } = useParams()
    const [map, setMap] = useState(/** @type google.maps.Map */(null))
    const [directionsResponse, setDirectionsResponse] = useState(null)
    const [position, setPosition] = useState([])
    const [centerPosition, setCenterPosition] = useState([])
    const [vitesse, setVitesse] = useState([])
    const [child, setChild] = useState([])
    const [distance, setDistance] = useState(0)
    const [duration, setDuration] = useState('')
    const app = initializeApp(firebaseConfig);
    // const analytics = getAnalytics(app);
    const db = getDatabase(app);

    // console.log("analytic", analytics);
    // console.log("db",db)

    /** @type React.MutableRefObject<HTMLInputElement> */
    const originRef = useRef()
    /** @type React.MutableRefObject<HTMLInputElement> */
    const destiantionRef = useRef()


    const getChild = async () => {
        setLoading(true)
        try {
            if (currentUser.role === 2) {
                const { data } = await axios.get(`${getChildrenBus}/${id}`)
                console.log(data)
                console.log(position)
                setChild(data)
                setCenterPosition(position)
                // eslint-disable-next-line no-undef
                const directionService = new window.google.maps.DirectionsService()
                const results = await directionService.route({
                    origin: position[0],
                    destination: data[0].ramassage[0],
                    travelMode: window.google.maps.TravelMode.DRIVING
                })
                setDirectionsResponse(results)
                setLoading(false)
                console.log(data)
                return
            }
            const { data } = await axios.get(`${getChildsOneDriver}/${id}`)
            setChild(data)
            console.log(data)
            console.log(position)
            // eslint-disable-next-line no-undef
            const directionService = new window.google.maps.DirectionsService()
            const results = await directionService.route({
                origin: position[0],
                destination: child[0].ramassage[0],
                travelMode: window.google.maps.TravelMode.DRIVING
            })
            setDirectionsResponse(results)
            setLoading(false)
            // console.log(data)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const getDriverPosition = () => {
        const dataRef = ref(db, `locations/${id}`)
        // console.log(dataRef)
        // console.log(id)
        // console.log("la")
        onValue(dataRef, (snapshot) => {
            setLoading(true)
            // console.log("ici")
            const data = snapshot.val()
            // console.log(data)
            if (data) {
                setPosition({
                    lat: data.location.latitude,
                    lng: data.location.longitude
                })
                if(child.length===0){
                    getChild()
                }
                setVitesse(data.speed)
                setLoading(false)
                return
                // console.log(data)
            }
        })
        // setLoading(false)
        // alert("position du chauffeur n'a pas pu être récupéré")
    }

   

    // useEffect(() => {
    //     getChild()
    // }, [])
    useEffect(() => {
        getDriverPosition() 
    }, [])
    setInterval(getDriverPosition, 3000);
    if (loading || !isLoaded  ) {
        return <Loader />
    }
    // useEffect(() => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(

    //             position => {
    //                 setPosition({
    //                     lat: position.coords.latitude,
    //                     lng: position.coords.longitude
    //                 })
    //                 console.log("Latitude : ", position.coords.latitude);
    //                 console.log("Longitude : ", position.coords.longitude);
    //             },
    //             error => {
    //                 console.error("Erreur : ", error);
    //             },
    //             {
    //                 enableHighAccuracy: true,
    //                 timeout: 5000,
    //                 maximumAge: 0
    //             }
    //         );
    //     } else {
    //         alert("Désolé, la géolocalisation n'est pas disponible !");
    //     }
    // }, [])

 

    // eslint-disable-next-line no-undef
    async function calculateRoute() {
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()
        let destination = []
        child.map(element => {
            // eslint-disable-next-line no-undef
            const waypoint1 = new window.google.maps.LatLng(element.ramassage[0].latitude, element.ramassage[0].lontidute)
            const coord = {
                location: waypoint1
            }
            destination.push(coord)
        })
        console.log(destination[destination.length - 1])
        console.log(position)
        // eslint-disable-next-line no-undef
        const waypoint1 = new window.google.maps.LatLng(Number(currentUser.latitude), Number(currentUser.longitude))
        const coord = {
            location: waypoint1
        }
        destination.push(coord)
        const results = await directionsService.route({
            origin: position,
            waypoints: destination,
            destination: destination[destination.length - 1],
            // eslint-disable-next-line no-undef
            travelMode: google.maps.TravelMode.DRIVING,
        })
        // console.log(results)
        setDirectionsResponse(results)
        // console.log(results)
        setDistance(results.routes[0].legs[0].distance.text)
        setDuration(results.routes[0].legs[0].duration.text)
    }
    function clearRoute() {
        setDirectionsResponse(null)
        setDistance('')
        setDuration('')
        originRef.current.value = ''
        destiantionRef.current.value = ''
    }
  
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Flex
                position='relative'
                flexDirection='column'
                alignItems='center'
                h='100vh'
                w='100vw'
            >
                <Box position='absolute' left={0} top={0} h='100%' w='100%'>
                    {/* Google Map Box */}
                    <GoogleMap
                        center={centerPosition}
                        zoom={15}
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        options={{
                            zoomControl: true,
                            streetViewControl: true,
                            mapTypeControl: true,
                            fullscreenControl: true,
                        }}
                        onLoad={map => setMap(map)}
                    >
                        <Marker
                            position={position}
                            icon={{
                                url: taxi,
                                // eslint-disable-next-line no-undef
                                scaledSize: new window.google.maps.Size(50, 50),
                            }}
                        />
                        {
                            child.map(item => {
                                let location = {
                                    lat: Number(item.ramassage[0].latitude),
                                    lng: Number(item.ramassage[0].lontidute)
                                }
                                // console.log(location);
                                return (
                                    <Marker
                                        key={item._id}
                                        markerId={item._id}
                                        icon={{
                                            url: `${OlinURL}/${item.photo}`,
                                            // eslint-disable-next-line no-undef
                                            scaledSize: new window.google.maps.Size(50, 50),
                                        }}
                                        position={location} />
                                )
                            })
                        }
                        {directionsResponse && (
                            <DirectionsRenderer directions={directionsResponse} />
                        )}
                    </GoogleMap>
                </Box>
                <Box
                    p={4}
                    borderRadius='lg'
                    m={4}
                    bgColor='white'
                    shadow='base'
                    minW='container.md'
                    zIndex='1'
                >
                    <HStack spacing={2} justifyContent='space-between'>
                        {/* <Box flexGrow={1}>
                            <Autocomplete>
                                <Input type='text' placeholder='Origin' ref={originRef} />
                            </Autocomplete>
                        </Box> */}
                        {/* <Box flexGrow={1}>
                            <Autocomplete>
                                <Input
                                    type='text'
                                    placeholder='Destination'
                                    ref={destiantionRef}
                                />
                            </Autocomplete>
                        </Box> */}

                        <ButtonGroup>
                            <Button colorScheme='pink' type='submit' onClick={calculateRoute}>
                                Afficher le Trajet
                            </Button>
                            {/* <IconButton
                                aria-label='center back'
                                icon={<FaTimes />}
                                onClick={clearRoute}
                            /> */}
                        </ButtonGroup>
                    </HStack>
                    <HStack spacing={4} mt={4} justifyContent='space-between'>
                        <Text>Distance: {distance} </Text>
                        <Text>Vitesse: {vitesse} </Text>
                        {/* <IconButton
                            aria-label='center back'
                            icon={<FaLocationArrow />}
                            isRound
                            onClick={() => {
                                map.panTo(position)
                                map.setZoom(15)
                            }}
                        /> */}
                    </HStack>
                </Box>
            </Flex>
            <Footer />
        </DashboardLayout>
    )

}

export default MyMap